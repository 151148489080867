import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'

import Moment from 'moment-timezone'
import Map from './tools/mapA'

import logo from '../assets/logo.svg'

import ReactToPrint from 'react-to-print'

import { Button } from '@mui/material'

export const MissionDetails = () => {
	const componentRef = useRef()

	const [mission, setMission] = useState('')

	const [center, setCenter] = useState({
		latitude: 46.2044,
		longitude: 6.1432,
		zoom: 13,
	})

	const { id } = useParams()

	const fetchData = async () => {
		await axios
			.get(`${process.env.REACT_APP_API_URL}/mission/` + id, {
				headers: {
					Authorization: 'Bearer ' + localStorage.getItem('token'),
				},
			})
			.then((res) => {
				setMission(res.data.mission)

				setCenter({
					latitude: res.data.mission.to.geometry.coordinates[1],
					longitude: res.data.mission.to.geometry.coordinates[0],
					zoom: 13,
				})
			})
	}

	useEffect(() => {
		fetchData()
	}, [])

	return (
		<div className='App'>
			<div className='app-logged'>
				<main className='mission-details' ref={componentRef}>
					<img src={logo} className='App-logo' alt='logo' width='340px' />

					<h1>Mission details #{mission.shortReference}</h1>

					<div className='from-to'>
						<span>{mission.from && mission.from.text} &#x2192; </span>
						<span>{mission.to && mission.to.text}</span>
					</div>

					<div className={mission.status} style={{ marginTop: '10px' }}>
						{mission.status}
					</div>
					{mission.deliveredAt && <div className='deliver-time'>@ {Moment(mission.deliveredAt).format('MMMM Do YYYY, h:mm:ss a')}</div>}
					<div className='mapa'>
						<Map centr={center} />
					</div>

					<div className='mission-details-container'>
						<div className='history'>
							<div>
								<b>Posted@</b>
								{Moment(mission.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
							</div>
							<div>
								<b>Scheduled@</b>
								{Moment(mission.time).format('MMMM Do YYYY, h:mm:ss a')}
							</div>
							<div>
								<hr />
								{mission.bookerDetails && (
									<div>
										<b>Booked@</b>

										{mission.bookerDetails.bookedAt ? Moment(mission.bookerDetails.bookedAt).format('MMMM Do YYYY, h:mm:ss a') : '-'}
									</div>
								)}

								<div>
									<b>Updated@</b>
									{mission.updatedAt ? Moment(mission.updatedAt).format('MMMM Do YYYY, h:mm:ss a') : '-'}
								</div>
								<div></div>
								<b>Delivered@</b>
								{mission.deliveredAt ? Moment(mission.deliveredAt).format('MMMM Do YYYY, h:mm:ss a') : '-'}
							</div>
						</div>
					</div>
				</main>
				<ReactToPrint
					trigger={() => {
						return (
							<Button variant='contained' href='#'>
								{' '}
								print
							</Button>
						)
					}}
					content={() => componentRef.current}
				/>
			</div>
		</div>
	)
}

export default MissionDetails
