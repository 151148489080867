import React, { useState, useContext, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'

import Head from '../tools/head'
import List from './postslist'

import Sticky from 'react-sticky-el'

import { UserContext, SocketContext } from '../../_context'
import get from 'lodash/get'
import _filter from 'lodash/filter'

import axios from 'axios'
import useSWR from 'swr'

import DetailsPop from './detailspop'
import { useTranslation } from 'react-i18next'

import NoConnection from '../tools/noconnection'
import ReactGA from 'react-ga'
import { Title, Stack, Modal } from '@mantine/core'

import Loading from '../tools/loading'

import PosterActions from './posterActions'

import { SegmentedControl } from '@mantine/core'

import { useViewportSize } from '@mantine/hooks'

import { Card, Text, Anchor, Badge, Group } from '@mantine/core'

import { useDisclosure, useMediaQuery } from '@mantine/hooks'
import { useHotkeys } from '@mantine/hooks'
const apiServer = process.env.REACT_APP_API_URL

const Welcome = () => {
   const nav = useNavigate()

   useHotkeys([
      [
         'space',
         () => {
            switch (filter) {
               case 'all':
                  setFilter('available')
                  break
               case 'available':
                  setFilter('booked')
                  break

               case 'booked':
                  setFilter('inTransit')
                  break

               default:
                  setFilter('all')
            }
         }
      ],
      ['enter', () => nav('/logged/poster/newmission')]
   ])

   const isMobile = useMediaQuery('(max-width: 50em)')

   const { t } = useTranslation()

   const { height, width } = useViewportSize()

   const socket = useContext(SocketContext)
   const [user] = useContext(UserContext)

   const fetcher = (url) => axios.get(url, config).then((res) => res.data)
   const { data, mutate, error } = useSWR(apiServer + '/poster/missions', fetcher, {
      onSuccess: (data) => {}
   })

   ReactGA.initialize(process.env.REACT_APP_GA_ID)

   useEffect(() => {
      ReactGA.event({
         category: 'Poster',
         action: 'Poster / ' + user.name,
         label: user.name
      })
   }, [user])

   useEffect(() => {
      socket.on('missionUpdate', (data) => {
         mutate()
      })
      // socket on connect
      socket.on('connect', () => {
         //call the server to register user vith the socket id and the user id
         socket.emit('userConnected', { id: user.id, name: user.name })
      })
      // socket on disconnect
   }, [socket, mutate])

   const config = {
      headers: { Authorization: `Bearer ${user.token}` }
   }

   let noBank = (
      <Card
         withBorder
         onClick={() => {
            nav('/logged/poster/billing')
         }}>
         <Stack direction='column' spacing={0} align='center' justify='center'>
            <Text size='sm'>{t('addMissionPl')}</Text>
            <Text size={10}>{t('addMissionPl1')}</Text>
         </Stack>
      </Card>
   )

   if (user.verified) {
      noBank = null
   }

   if (user.business) {
      noBank = null
   }

   const [show, setShow] = useState(false)

   const [selectedMission, setSelectedMission] = useState(null)

   const [filter, setFilter] = useState('all')

   if (error) return <NoConnection />
   if (!data) return <Loading />

   const setSelected = (e) => {
      setSelectedMission(e)
      setShow(true)
   }

   const SegmentControl = () => {
      return (
         <SegmentedControl
            size='sm'
            data={[
               { label: t('all'), value: 'all' },
               { label: t('waiting'), value: 'available' },
               { label: t('booked'), value: 'booked' },
               { label: t('InTransit'), value: 'inTransit' }
            ]}
            value={filter}
            onChange={(value) => {
               setFilter(value)
            }}
         />
      )
   }

   const listOfMissions = (
      <Stack>
         {_filter(data.missions, function (o) {
            return o.status === filter || filter === 'all'
         }).map((mission) => (
            <List mission={mission} key={mission._id} setSelectedMission={setSelected} />
         ))}
      </Stack>
   )

   const MissionDetailsModal = () => {
      return (
         <Modal fullScreen={isMobile} size='lg' opened={show} onClose={() => setShow(false)}>
            <DetailsPop mission={selectedMission} setShow={setShow} />
         </Modal>
      )
   }

   const Header = () => {
      return (
         <Group position='apart'>
            <Title order={6}>{t('p_title1')}</Title>

            <Anchor size='xs' color='dimmed' sx={{ lineHeight: 1 }}>
               <Text tt='uppercase'>
                  SHOWING {filter === 'all' ? 'All' : ''}
                  {filter !== 'all' ? data[filter]?.length : ''} OF {data.length.missions} {t('missions')}
               </Text>
            </Anchor>
         </Group>
      )
   }

   const NoMissions = () => {
      return (
         <Card withBorder>
            <Stack align='center' justify='center' spacing={1} style={{ minHeight: '400px' }}>
               <Text size='xs' mt={7} tt='uppercase'>
                  {t('No Missions ')}
               </Text>
               <Badge size='xs' mt={7}>
                  {filter === 'all' && data.length.missions}
               </Badge>
            </Stack>
         </Card>
      )
   }

   return (
      <>
         <Head type='main' />

         <MissionDetailsModal />
         <Stack>
            {get(user, 'cardInfo.number', false) ? null : noBank}
            <Card>
               <Header />
               <Stack>
                  <PosterActions />
                  <Sticky>
                     <Stack>
                        <SegmentControl />
                     </Stack>
                  </Sticky>
                  {filter === 'all' && data.length.missions < 1 ? <NoMissions /> : null}
                  {data[filter]?.length < 1 ? (
                     <Text size='xs' mt={7}>
                        <NoMissions />
                     </Text>
                  ) : (
                     listOfMissions
                  )}
               </Stack>
            </Card>
         </Stack>
      </>
   )
}

export default Welcome
