//import {useRef,useState,useContext,useEffect} from 'react';
// Importing Components
//import { GlobalContext } from "../_context";
 
import { useEffect } from 'react'

import { Flex, Stack, Button, useMantineTheme } from '@mantine/core'

import logo from '../assets/logo.svg'

import { useTranslation } from 'react-i18next'
import { Image, Group } from '@mantine/core'

import swiss from '../assets/swiss.svg'

import HomeButtons from './tools/homeButtons'

import { FiInstagram } from "react-icons/fi";

const Welcome = ({lng}) => {
	const { colorScheme } = useMantineTheme()

	//const [navigation,setNavigation] = useContext(GlobalContext);

	//const [menuFullyOpened,setMenuFullyOpened] = useState(false)

	
	useEffect(function(){ lng && i18n.changeLanguage(lng) },[])

	
	  
	const { i18n } = useTranslation()

	

	return (
		<Flex mih={'98vh'} justify='space-between' direction={'column'}>
			<div className={colorScheme === 'light' ? 'header__background_light' : 'header__background_dark'}></div>
			<div></div>
			<Stack>
				<img src={logo} className='App-logo' alt='logo' width={'100%'} />

				<HomeButtons />
			</Stack>

			<Group position='center'>
			<Button   size='xs' variant="filled" color="pink" value={'en'} alt="instagram"  onClick={ () => window.open('https://www.instagram.com/mypedallo/') } >
				<FiInstagram />
				</Button>
				<Button.Group>
				
					<Button size='xs' color='dark' value={'en'} onClick={() => i18n.changeLanguage('en')}>
						EN
					</Button>
					<Button
						size='xs'
						color='dark'
						value={'fr'}
						onClick={() => {
							i18n.changeLanguage('fr')
						}}>
						FR
					</Button>
				</Button.Group>
				<Image radius={'xl'} className='card-image' src={swiss} alt='Made Swiss' maw={200} />
			</Group>
		</Flex>
	)
}

export default Welcome
