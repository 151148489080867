import React from 'react'
import moment from 'moment'

import { BsFillTelephoneForwardFill } from 'react-icons/bs'
import { ActionIcon, Anchor, Card, Group, Stack, Text } from '@mantine/core'

function bookerbox({ mission }) {
	const namer = mission.posterDetails.name

	const name = namer.split(' ')[0]

	console.log(mission)

	return (
		<Card withBorder>
			<Group position='apart'>
				<Stack spacing={1}>
					<Text size='xs' weight={700} mt={1}>
						Posted @ <b>{moment(mission.time).calendar()}</b>
					</Text>
					<Text size={'xs'} c='dimmed'>
						By {name} {'tel:' + mission.posterDetails.phone}
					</Text>
				</Stack>

				<Group position='apart'>
					<Anchor href={'tel:' + mission.posterDetails.phone}>
						<ActionIcon variant='filled' size='xl'>
							<BsFillTelephoneForwardFill />
						</ActionIcon>
					</Anchor>
				</Group>
			</Group>
		</Card>
	)
}

export default bookerbox
