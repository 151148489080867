import React from 'react'
import { UnstyledButton, Checkbox, Text, SimpleGrid, createStyles, rem, Stack } from '@mantine/core'
import { useUncontrolled } from '@mantine/hooks'
import icons from './icons'
import { useTranslation } from 'react-i18next'

const useStyles = createStyles((theme, { checked }) => ({
   button: {
      transition: 'background-color 150ms ease, border-color 150ms ease',
      border: `${rem(1)} solid ${checked ? theme.fn.variant({ variant: 'outline', color: theme.primaryColor }).border : theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]}`,
      borderRadius: theme.radius.sm,
      padding: '10px',

      color: theme.colorScheme === 'dark' ? '#fff' : theme.black,
      backgroundColor: checked ? theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background : theme.colorScheme === 'dark' ? '#25262d' : '#fcfcfc',
      '&:hover': {
         borderColor: checked ? theme.primaryColor : theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3],
         backgroundColor: checked ? theme.fn.variant({ variant: 'light', color: theme.primaryColor }).background : theme.colorScheme === 'dark' ? '#25262d' : '#fcfcfc'
      }
   },

   body: {
      flex: 1,
      marginLeft: theme.spacing.md
   }
}))

export function ImageCheckbox({ checked, defaultChecked, onChange, title, description, className, image, ...others }) {
   const [value, handleChange] = useUncontrolled({
      value: checked,
      defaultValue: defaultChecked,
      finalValue: false,
      onChange
   })

   const { classes, cx } = useStyles({ checked: value })

   return (
      <UnstyledButton {...others} onClick={() => handleChange(!value)} className={cx(classes.button, className)}>
         <Stack align='center' spacing={5}>
            {image}
            <Stack align='center' spacing={10}>
               <Text size={9} style={{ minHeight: '20px' }}>
                  {title}
               </Text>

               <Checkbox checked={value} onChange={(e) => onChange} tabIndex={-1} styles={{ input: { cursor: 'pointer' } }} />
            </Stack>
         </Stack>
      </UnstyledButton>
   )
}

export function Requirements({ requirements, setRequirements }) {
   const { t } = useTranslation()
   const mockdata = [
      { title: t('food'), image: icons.food, value: requirements?.Food, onChange: (value) => setRequirements({ ...requirements, Food: value }) },
      { title: t('keepCold'), image: icons.keepCold, value: requirements?.KeepCold, onChange: (value) => setRequirements({ ...requirements, KeepCold: value }) },
      { title: t('warm'), image: icons.warm, value: requirements?.Warm, onChange: (value) => setRequirements({ ...requirements, Warm: value }) },
      { title: t('documents'), image: icons.documents, value: requirements?.Documents, onChange: (value) => setRequirements({ ...requirements, Documents: value }) },
      { title: t('fragile'), image: icons.fragile, value: requirements?.Fragile, onChange: (value) => setRequirements({ ...requirements, Fragile: value }) },
      { title: t('urgent'), image: icons.urgent, value: requirements?.Urgent, onChange: (value) => setRequirements({ ...requirements, Urgent: value }) },
      { title: t('critical'), image: icons.critical, value: requirements?.Critical, onChange: (value) => setRequirements({ ...requirements, Critical: value }) }
   ]

   const items = mockdata.map((item, key) => <ImageCheckbox {...item} defaultChecked={item.value} key={key} description={item.title} />)
   return (
      <SimpleGrid
         cols={4}
         spacing='sm'
         breakpoints={[
            { minWidth: 'md', cols: 4 },
            { minWidth: 'lg', cols: 7 }
         ]}>
         {items}
      </SimpleGrid>
   )
}

export default Requirements
