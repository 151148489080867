const otherCharges = 0.4

export const getZone = (selectedCity, km) => {
	const z = selectedCity.zones

	if (km >= Number(z.MaxRange.distance)) return false

	if (km >= Number(z.zone4.distance)) return z.zone4

	if (km >= Number(z.zone3.distance)) return z.zone4

	if (km >= Number(z.zone2.distance)) return z.zone3

	if (km >= Number(z.zone1.distance)) return z.zone2

	if (km >= 0 && km < Number(z.zone1.distance)) return z.zone1
}

export const getDistanceFromLatLonInKm = async (lat1, lon1, lat2, lon2) => {
	// use mapbox directions api to get driving distance berween two points

	// todo : concider vehicle type

	var url = `https://api.mapbox.com/directions/v5/mapbox/driving/${lon1},${lat1};${lon2},${lat2}?access_token

	=${process.env.REACT_APP_MAPBOX_API_KEY}`

	var res = await fetch(url)

	var data = await res.json()

	var km = data.routes[0].distance / 1000

	return km
}

export const getDrivingDirectionMarkers = async (lat1, lon1, lat2, lon2) => {
	var url = `https://api.mapbox.com/directions/v5/mapbox/driving/${lon1},${lat1};${lon2},${lat2}?access_token

	=${process.env.REACT_APP_MAPBOX_API_KEY}`

	var res = await fetch(url)

	var data = await res.json()

	console.log('getDrivingDirectionMarkers', data)
}

export const calcHourly = (requirements, selectedCity, hourly, hourlyRate, vehicle) => {
	const cityHourlyRate = selectedCity.zones.hourlyRate
	const post = parseFloat(selectedCity.zones.postingFee)
	const coefficient = selectedCity.zones?.coefficiency[vehicle] ?? 1

	const fee = parseFloat(cityHourlyRate) * parseFloat(hourlyRate) * parseFloat(coefficient)

	const heavyMarkup = (fee * parseFloat(selectedCity.zones?.heavyMarkup ?? 0)) / 100
	const finalFee = requirements['HeavyBulky'] ? fee + heavyMarkup : fee
	const bookerSee = finalFee - post
	const bookerNet = bookerSee - (bookerSee * parseFloat(selectedCity.zones?.commissionRate ?? 0)) / 100 - otherCharges
	const ret = {
		posterCharge: parseFloat(finalFee.toFixed(2)),
		bookerSee: parseFloat(bookerSee.toFixed(2)),
		bookerNet: parseFloat(bookerNet.toFixed(2)),
		currency: selectedCity.currency.name,
	}
	console.log('charges', ret)
	return ret
}

export const calcDistance = (requirements, selectedCity, e, vehicle) => {
	const selectedZone = getZone(selectedCity, e)

	if (!selectedZone) return 'Out of Delivery Range'

	const coefficient = selectedCity.zones?.coefficiency[vehicle] ?? 1

	const finalFee = parseFloat(selectedZone.fee) * parseFloat(coefficient) * (1 + (parseFloat(selectedCity.zones?.heavyMarkup ?? 0) / 100) * requirements['HeavyBulky'])

	const post = parseFloat(selectedCity.zones?.postingFee ?? 0)
	const option = parseFloat(selectedZone.optionCost ?? 0)
	const bookerSee = finalFee - post
	const bookerNet = bookerSee - (bookerSee * option) / 100 - otherCharges

	const ret = {
		posterCharge: parseFloat(finalFee.toFixed(2)),
		bookerSee: parseFloat(bookerSee.toFixed(2)),
		bookerNet: parseFloat(bookerNet.toFixed(2)),
		currency: selectedCity.currency.name,
	}

	return ret
}
