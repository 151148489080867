import React from 'react'
import {VscDebugDisconnect} from 'react-icons/vsc'


export default function Loading() {
	return (
	 
		
	<div class='noconnect'>
		<div className='conn-isue'>
		<div><VscDebugDisconnect size={100} /></div> 
		<div>Connection Issues</div>
		</div>
	</div>
	)
}
