import { createStyles, Text, SimpleGrid, UnstyledButton, rem } from '@mantine/core'
import { IconBuildingBank } from '@tabler/icons-react'

import { MdOutlineDeliveryDining } from 'react-icons/md'

import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'

const useStyles = createStyles((theme) => ({
   card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0]
   },

   title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontWeight: 700
   },

   item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: theme.radius.md,
      height: rem(90),
      border: '1px solid #f2f2f2',
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : '#fff',
      transition: 'box-shadow 150ms ease, transform 100ms ease',

      '&:hover': {
         boxShadow: theme.shadows.md,
         transform: 'scale(1.05)'
      }
   }
}))

function PosterActions() {
   const { t } = useTranslation()

   const navigate = useNavigate()

   const mockdata = [
      {
         title: t('p_postmission'),
         icon: MdOutlineDeliveryDining,
         color: 'red',
         onClick: () => {
            navigate('/logged/poster/newmission')
         }
      },
      {
         title: t('getStatement'),
         icon: IconBuildingBank,
         color: 'indigo',
         onClick: () => {
            navigate('/logged/poster/statements')
         }
      }
   ]

   const { classes, theme } = useStyles()

   const items = mockdata.map((item) => (
      <UnstyledButton key={item.title} className={classes.item} onClick={item.onClick}>
         <item.icon color={theme.colors[item.color][6]} size='2rem' />
         <Text size='xs' mt={7}>
            {item.title}
         </Text>
      </UnstyledButton>
   ))

   return (
      <SimpleGrid cols={2} mt='xs'>
         {items}
      </SimpleGrid>
   )
}

export default PosterActions
