import React, { useContext, useState } from 'react'
import { createStyles, Card, Text, SimpleGrid, UnstyledButton, Anchor, Group, Badge } from '@mantine/core'

import { FiPrinter, FiDownloadCloud, FiSearch } from 'react-icons/fi'

import Head from './tools/head'

import Loading from './tools/loading'

import axios from 'axios'
import useSWR from 'swr'
import { UserContext } from '../_context'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

import NoConnection from './tools/noconnection'

import { Table, Stack } from '@mantine/core'

import logo from '../assets/logo.svg'

import StatementsCalendar from './tools/statementsCalendar'

import { usePDF } from 'react-to-pdf'

const apiServer = process.env.REACT_APP_API_URL

const useStyles = createStyles((theme) => ({
   card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0]
   },

   item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: theme.radius.md,
      border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]}`,
      padding: theme.spacing.md,

      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],

      '&:hover': {
         backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1]
      }
   },
   title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontWeight: 700
   }
}))

const Item = (item, i) => {
   return (
      <>
         <tr key={item._id}>
            <td>
               {item.type === 'business' && <Badge color='blue'>{item.type}</Badge>}
               {item.type === 'payment' && <Badge color='red'>{item.type}</Badge>}
               {item.type === 'payout' && <Badge color='green'>{item.type}</Badge>}
               {item.createType === 'API-Zone' && <Badge color='lime'>Pre-Paid</Badge>}
               {!item.type && <Badge color='yellow'>Archived</Badge>}
            </td>
            <td>{moment(item.date).format('LLL')} </td>

            <td>
               {item.ShortReference} {item.internalReference}
            </td>
            <td>{item.bookerDetails?.name.split(' ')?.[0]}</td>

            <td>
               {item.type === 'business' && (
                  <Badge variant='gradient' gradient={{ from: 'blue', to: 'red' }}>
                     - {item.charged} {item.currency}
                  </Badge>
               )}
               {item.type === 'payment' && (
                  <Badge variant='gradient' gradient={{ from: 'orange', to: 'red' }}>
                     - {item.charged} {item.currency}
                  </Badge>
               )}
               {item.type === 'payout' && (
                  <Badge variant='gradient' gradient={{ from: 'teal', to: 'lime', deg: 105 }}>
                     + {item.bookerNet} {item.currency}
                  </Badge>
               )}
               {!item.type && (
                  <Badge variant='outline' color='yellow'>
                     {item.bookerNet} {item.currency}
                  </Badge>
               )}
            </td>
         </tr>
         <tr>
            {item.createType === 'API-Zone' && (
               <td>
                  <Text size='xs' color='dimmed'>
                     Posted and Paid for by PicNicPay
                  </Text>
               </td>
            )}
         </tr>
      </>
   )
}

const Statements = ({ userType }) => {
   const { t } = useTranslation()
   const [user] = useContext(UserContext)

   const { classes, theme } = useStyles()

   const [selected, setSelected] = useState()

   const { toPDF, targetRef } = usePDF({ filename: 'statements-pedallo.pdf' })

   const mockdata = [
      {
         title: t('print'),
         icon: FiPrinter,
         color: 'red',
         onClick: () => toPDF()
      },
      {
         title: t('download'),
         icon: FiDownloadCloud,
         color: 'indigo',
         onClick: () => toPDF()
      },

      {
         title: 'search',
         icon: FiSearch,
         color: 'teal',
         onClick: () => {}
      }
   ]

   const config = {
      headers: { Authorization: `Bearer ${user.token}` }
   }

   const fetcher = (url) => axios.get(url, config).then((res) => res.data)

   const { data, error } = useSWR(`${apiServer}/poster/statements`, fetcher)

   if (error) return <NoConnection />

   if (!data) return <Loading />

   //const total = sum(map(data.statements, (item) => (Number(item.pricing?.price?.bookerSee) ? Number(item.pricing?.price?.bookerSee) : 0))).toFixed(2)

   console.log(data)

   var counts = data.statements.reduce((p, c) => {
      var time = moment(c.deliveredAt).format('YYYY/MM/DD')
      if (!p.hasOwnProperty(time)) {
         p[time] = 0
      }
      p[time]++
      return p
   }, {})

   var countsExtended = Object.keys(counts).map((k) => {
      return { date: k, count: counts[k] }
   })

   return (
      <Stack>
         {userType === 'booker' ? <Head type='mainbooker' /> : <Head type='main' />}
         <Card withBorder ref={targetRef}>
            <Stack>
               <Group position='apart'>
                  <Text className={classes.title}>{t('statements')}</Text>
                  <Anchor size='xs' color='dimmed' sx={{ lineHeight: 1 }}>
                     {selected ? moment(selected).format('DD/MM/YYYY') : ' ' + data.statements[0]?.pricing?.currency?.name}
                  </Anchor>
               </Group>

               <Stack style={{ padding: 10 }}>
                  <StatementsCalendar value={countsExtended} selected={selected} setSelected={setSelected} />
                  <img src={logo} className='App-logo' alt='logo' width='200px' />
                  <Table fontSize='xs' striped>
                     <thead>
                        <tr>
                           <th>Type</th>
                           <th>Date</th>
                           <th>Symbol(Int.Ref)</th>
                           <th>Booker Name</th>

                           <th>Total+</th>
                        </tr>
                     </thead>
                     <tbody>{data && data.statements.map((item, i) => Item(item, i))}</tbody>
                  </Table>
               </Stack>
            </Stack>
         </Card>

         <Card withBorder className={classes.card}>
            <SimpleGrid cols={4} mt='md' spacing={5}>
               {mockdata.map((item) => (
                  <UnstyledButton padding={10} key={item.title} className={classes.item} onClick={item.onClick}>
                     <item.icon color={theme.colors[item.color][6]} size='2rem' />
                     <Text size='xs' mt={7}>
                        {item.title}
                     </Text>
                  </UnstyledButton>
               ))}

               <Stack justify='center' spacing={0} style={{ paddingLeft: '30px' }}>
                  <Text size='sm' color='dimmed'>
                     {t('Total')}
                  </Text>
                  <Text size='lg' fw={700}>
                     {data.statements[0]?.currency}
                  </Text>
               </Stack>
            </SimpleGrid>
         </Card>
      </Stack>
   )
}

export default Statements
