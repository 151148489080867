import React, { useState, useRef, useContext } from 'react'
import axios from 'axios'

import { Button, TextInput, PasswordInput, Checkbox, Text, Anchor, Group, Card, Space, Stack } from '@mantine/core'
import { IconLock } from '@tabler/icons-react'
import { useFormik } from 'formik'
import { Link, useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import { IconAt, IconMail } from '@tabler/icons-react'

import { UserContext } from '../../_context'

import { useTranslation } from 'react-i18next'

import ForgotPasswordInput from '../tools/forgatYourPassInput.js'

const LoginForm = ({ userType }) => {
	const { t } = useTranslation()

	const apiServer = process.env.REACT_APP_API_URL

	const validationSchema = yup.object({
		email: yup.string('Enter a email').min(3, t('emailval')).email(t('emailval')).required(t('emailreq')),

		password: yup.string('Enter your details').min(4, t('passval1')).required(t('passreq')),
	})

	const navigate = useNavigate()

	const password = useRef(null)

	const [invalid, setInvalid] = useState('')

	const [waiting, setWaiting] = useState(false)

	const [user, setUser] = useContext(UserContext)

	var tt = localStorage.getItem('remember') ? localStorage.getItem('remember') : 'false'

	const [remember, setRemember] = useState(JSON.parse(tt))

	const errorMessageTimeout = 4000

	/// console.log(user)

	const log = async (obj, actions) => {
		setWaiting(true)
		await axios
			.post(apiServer + '/login/', obj)

			.then((response) => {
				setUser(response.data.user)
				localStorage.setItem('user', JSON.stringify(response.data.user)) // save user to local storage
				navigate('/logged/' + userType, { user })
			})
			.catch((error) => {
				!error.response ? setInvalid('API IS NOT RESPONDING') : setInvalid('Email or Password is invalid')

				setTimeout(() => setInvalid(''), errorMessageTimeout)
				setWaiting(false)
			})
			.finally(() => {})
	}

	const initVals = {
		email: localStorage.getItem('remember') ? (localStorage.getItem('email') ? localStorage.getItem('email') : '') : '' && localStorage.getItem('email'),
		password: '',
	}

	const formik = useFormik({
		enableReinitialize: true,
		validationSchema: validationSchema,
		initialValues: initVals,
		onSubmit: (values, actions) => {
			log(values, actions)
		},
	})

	return (
		<Card>
			<form onSubmit={formik.handleSubmit}>
				<Stack spacing={40}>
					<Space size='xl' />

					<TextInput icon={<IconMail size='1rem' />} placeholder={t('email')} size='xl' id='email' value={formik.values.email} onChange={formik.handleChange} autoComplete='email' error={formik.touched.email && Boolean(formik.errors.email)} />

					<PasswordInput icon={<IconLock size='1rem' />} placeholder='Password' size='xl' id='password' value={formik.values.password} onChange={formik.handleChange} error={formik.touched.password && Boolean(formik.errors.password)} />

					<Group position='apart'>
						<Checkbox
							checked={remember}
							label={t('remember')}
							onChange={() => {
								setRemember(!remember)
								localStorage.setItem('remember', !remember)

								remember && localStorage.removeItem('email')

								!remember && formik.values.email !== null && localStorage.setItem('email', formik.values.email)

								!formik.values.email && formik.setFieldValue('email', '')
							}}
						/>
						<Anchor href='/forgot'>
							<Text size={12}>Forgot your password?</Text>
						</Anchor>
					</Group>

					<Button loading={waiting} size='lg' type='submit'>
						{t('login')}
					</Button>
					<div className='invalid'>{invalid}</div>
				</Stack>
			</form>
		</Card>
	)
}
export default LoginForm
