import React, { createContext, useState } from 'react'
import socketio from 'socket.io-client'

export const UserContext = createContext()

const user = JSON.parse(localStorage.getItem('user') || 'null') || 'poster'

export const GlobalProvider = ({ children }) => {
	var loc = JSON.parse(localStorage.getItem('user'))

	var pop = loc ? loc : 'poster'

	const [user, SetUser] = useState(pop)

	return <UserContext.Provider value={[user, SetUser]}>{children}</UserContext.Provider>
}

const userToken = user.token || false

export const socket = socketio(process.env.REACT_APP_IO_URL, { query: { token: userToken } })

export const SocketContext = createContext(socket)
