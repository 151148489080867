import React, { useState, useRef, useCallback, useEffect } from 'react'

import MapGL, { Marker } from 'react-map-gl'

import axios from 'axios'

import Pin from './Pin.js'

import '../tools/mapbox-gl-geocoder.css'
import 'mapbox-gl/dist/mapbox-gl.css'

// Please be a decent human and don't abuse my Mapbox API token.
// If you fork this sandbox, replace my API token with your own.
// Ways to set Mapbox token: https://uber.github.io/react-map-gl/#/Documentation/getting-started/about-mapbox-tokens

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_KEY

const Map = ({ func, centr }) => {
   const [locatorLoading, setLocatorLoading] = useState(false)

   const [viewport, setViewport] = useState(centr)

   const mapRef = useRef()

   const geocoderContainerRef = useRef()

   const [marker, setMarker] = useState({
      latitude: centr?.latitude,
      longitude: centr?.longitude
   })

   const handleViewportChange = useCallback((newViewport) => {
      setViewport(newViewport)
      setLocatorLoading(true)

      setMarker({
         longitude: newViewport.longitude,
         latitude: newViewport.latitude
      })
   }, [])

   const handleResult1 = (e) => {
      setLocatorLoading(true)
      axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${viewport.longitude},${viewport.latitude}.json?access_token=${MAPBOX_TOKEN}`).then(({ data }) => {
         func({ result: data.features[0] })

         setLocatorLoading(false)
      })
   }

   useEffect(() => {
      axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${centr?.longitude},${centr?.latitude}.json?access_token=${MAPBOX_TOKEN}`).then(({ data }) => {
         func({ result: data.features[0] })

         setLocatorLoading(false)
      })
   }, [])

   return (
      <div style={{ height: '400px', width: '100%' }}>
         <div ref={geocoderContainerRef} style={{ marginBottom: '20px' }} />

         <MapGL ref={mapRef} {...viewport} width='100%' height='100%' onTransitionEnd={handleResult1} onViewportChange={handleViewportChange} mapboxApiAccessToken={MAPBOX_TOKEN}>
            {locatorLoading ? <div style={{ margin: '10px' }}>Locating...</div> : <div></div>}

            <Marker longitude={marker.longitude} latitude={marker.latitude} con anchor='bottom'>
               <Pin size={20} />
            </Marker>
         </MapGL>
      </div>
   )
}

export default Map
