import React, { useContext } from 'react'

import { SlRocket, SlLayers } from 'react-icons/sl'
import { useNavigate } from 'react-router-dom'

import { UserContext } from '../../_context'

import { useTranslation } from 'react-i18next'
import { ActionToggle } from './actionToggle.tsx'

import { MdOutlineFingerprint, MdOutlineTransferWithinAStation } from 'react-icons/md'
import { Group, Menu, Text, ActionIcon, Stack, useMantineColorScheme, Badge } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { IconSearch, IconTrash, IconChevronRight } from '@tabler/icons-react'

import { BsBoxSeam } from 'react-icons/bs'
import { spotlight } from '@mantine/spotlight'
// MENU SECTION	SETTINGS ####

import { FiX, FiHome, FiDownloadCloud, FiUser, FiCompass } from 'react-icons/fi' // imports
import { RiShieldStarFill, RiMapPinUserFill } from 'react-icons/ri'

const menuSize = '1rem'
const menuWidth = 255

const Head = ({ type }) => {
   const { colorScheme } = useMantineColorScheme()

   const { t } = useTranslation()

   const [user, setUser] = useContext(UserContext)

   const navigate = useNavigate()

   const padding = '20px 20px 20px 20px'

   const Ai = (props) => {
      return (
         <Group position='right' spacing='xs'>
            <ActionToggle />
            <Menu.Target>
               <ActionIcon variant='transparent' size={45}>
                  <RiMapPinUserFill size={50} color={colorScheme === 'light' ? '#444' : '#fff'} />
               </ActionIcon>
            </Menu.Target>
         </Group>
      )
   }

   if (type === 'sub') {
      return (
         <div className='app-logged-header'>
            <div> </div>
            <div className='app-logged-header-right'>
               <div onClick={(e) => navigate(-1)} className='loged-back'>
                  <FiX />
               </div>
            </div>
         </div>
      )
   }

   if (type === 'main') {
      return (
         <Group position='apart' grow style={{ padding: padding }}>
            <Stack spacing={0}>
               <b>{t('h_welecome')},</b>
               {user.name}
            </Stack>

            <Stack spacing={0}>
               <Group position='right' spacing='xs'>
                  <div className='app-logged-header-left'>
                     <Menu shadow='xl' width={menuWidth} position='bottom' withArrow arrowPosition='center'>
                        <Ai />
                        <Menu.Dropdown>
                           <Menu.Item onClick={() => navigate('/logged/poster/newmission')} icon={<SlRocket size='2rem' stroke='2' />} color='red'>
                              <Text weight={500}>{t('Order New')}</Text>
                              <Text size='xs' color='dimmed'>
                                 Delivery Mission
                              </Text>
                           </Menu.Item>

                           <Menu.Item rightSection={<IconChevronRight size='1rem' stroke={1.5} />} onClick={() => navigate('/logged/poster/profile', { user })}>
                              <Group>
                                 <FiUser />
                                 <div>
                                    <Text weight={500}>{user.name}</Text>
                                    <Text size='xs' color='dimmed'>
                                       {user.email}
                                    </Text>
                                 </div>
                              </Group>
                           </Menu.Item>

                           <Menu.Item onClick={() => navigate('/logged/poster/billing', { user })} icon={<MdOutlineFingerprint size={menuSize} />}>
                              {t('h_personal')}
                           </Menu.Item>
                           <Menu.Item onClick={() => navigate('/logged/poster/', { user })} icon={<FiHome size={menuSize} />}>
                              {t('dashboard')}
                           </Menu.Item>
                           <Menu.Divider />
                           <Menu.Item onClick={() => navigate('/logged/poster/statements', { user })} icon={<SlLayers size={menuSize} />}>
                              {t('h_statement')}
                           </Menu.Item>
                           <Menu.Item
                              onClick={() => {
                                 spotlight.open()
                              }}
                              icon={<IconSearch size={menuSize} />}
                              rightSection={
                                 <Text size='xs' color='dimmed'>
                                    ⌘S
                                 </Text>
                              }>
                              {t('h_search')}
                           </Menu.Item>
                           <Menu.Item
                              onClick={() => {
                                 navigate('/logged/booker')
                              }}
                              icon={<MdOutlineTransferWithinAStation size={menuSize} />}>
                              <Group position='apart'>
                                 <Text>{t('h_Switch')}</Text>

                                 <Badge variant='gradient' gradient={{ from: 'lime', to: 'darkgreen', deg: 35 }}>
                                    {t('booker')}
                                 </Badge>
                              </Group>
                           </Menu.Item>
                           <Menu.Divider />
                           <Menu.Item
                              onClick={() => {
                                 notifications.show({
                                    title: 'Download all your Mission datass',
                                    message: 'This feature will be released with ver (1.3_alpha) 🤥',
                                    icon: <RiShieldStarFill size={menuSize} />,
                                    color: 'red'
                                 })
                              }}
                              icon={<FiDownloadCloud size={menuSize} />}>
                              {t('h_download')}
                           </Menu.Item>
                           <Menu.Item
                              icon={<IconTrash size={menuSize} />}
                              color='red'
                              onClick={() => {
                                 setUser(false)

                                 localStorage.removeItem('user')
                                 navigate('/')
                              }}>
                              {' '}
                              {t('h_logout')}{' '}
                           </Menu.Item>
                           <Menu.Divider />
                           <Menu.Item onClick={() => navigate('/logged/poster/easyQr', { user })}>
                              <img src='../../bnr_easyqr_banner.png' alt='user' className='user-image' width='100%' />
                           </Menu.Item>
                           <Menu.Item onClick={() => navigate('/logged/poster/easyButtons', { user })}>
                              <img src='../../bnr_easyflick_banner.png' alt='user' className='user-image' width='100%' />
                           </Menu.Item>
                        </Menu.Dropdown>
                     </Menu>
                  </div>
               </Group>
            </Stack>
         </Group>
      )
   }

   if (type === 'mainbooker') {
      return (
         <Group position='apart' grow style={{ padding: padding }}>
            <Stack spacing={0}>
               <b>{t('h_welecome')},</b>
               {user.name}
            </Stack>
            <Stack spacing={0}>
               <div className='app-logged-header-right'>
                  <Menu shadow='xl' width={menuWidth} position='bottom' withArrow arrowPosition='center' transitionProps={{ transition: 'rotate-right', duration: 150 }}>
                     <Ai />

                     <Menu.Dropdown>
                        <Menu.Item rightSection={<IconChevronRight size='1rem' stroke={1.5} />} onClick={() => navigate('/logged/booker/profile', { user })}>
                           <Group>
                              <FiUser />

                              <div>
                                 <Text weight={500}>{user.name}</Text>
                                 <Text size='xs' color='dimmed'>
                                    {user.email}
                                 </Text>
                              </div>
                           </Group>
                        </Menu.Item>
                        <Menu.Item onClick={() => navigate('/logged/booker/billing', { user })} icon={<MdOutlineFingerprint size={menuSize} />}>
                           {t('h_personal')}
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item onClick={() => navigate('/logged/booker/dashboard', { user })} icon={<FiHome size={menuSize} />}>
                           {t('Dashboard')}
                        </Menu.Item>

                        <Menu.Item onClick={() => navigate('/logged/booker/statement', { user })} icon={<SlLayers size={menuSize} />}>
                           {t('h_statement')}
                        </Menu.Item>

                        <Menu.Divider />

                        <Menu.Item onClick={() => navigate('/logged/booker', { user })} icon={<FiCompass size={menuSize} />}>
                           <Group position='apart'>
                              <Text>{t('h_city')}</Text>
                              <Badge variant='gradient' gradient={{ from: 'green', to: 'darkgreen', deg: 35 }}>
                                 {' '}
                                 {sessionStorage.getItem('city') ? sessionStorage.getItem('city') : 'Geneva'}
                              </Badge>
                           </Group>
                        </Menu.Item>

                        <Menu.Item onClick={() => navigate('/logged/poster', { user })} icon={<BsBoxSeam size={menuSize} />}>
                           <Group position='apart'>
                              <Text>{t('h_Switch')}</Text>
                              <Badge variant='gradient' gradient={{ from: 'red', to: 'darkred', deg: 35 }}>
                                 {t('poster')}
                              </Badge>
                           </Group>
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                           onClick={() => {
                              console.log(notifications)
                              spotlight.open()
                           }}
                           icon={<IconSearch size={menuSize} />}
                           rightSection={
                              <Text size='xs' color='dimmed'>
                                 ⌘S
                              </Text>
                           }>
                           Search
                        </Menu.Item>

                        <Menu.Item
                           onClick={() => {
                              notifications.show({
                                 title: 'Download all your Mission datass',
                                 message: 'This feature will be coming soon!🤥',
                                 icon: <RiShieldStarFill size={menuSize} />,
                                 color: 'red'
                              })
                           }}
                           icon={<FiDownloadCloud size={menuSize} />}>
                           Download my data
                        </Menu.Item>
                        <Menu.Divider />
                        <Menu.Item
                           color='red'
                           icon={<IconTrash size={menuSize} />}
                           onClick={() => {
                              setUser(false)
                              localStorage.removeItem('user')
                              navigate('/')
                           }}>
                           {' '}
                           {t('h_logout')}{' '}
                        </Menu.Item>
                     </Menu.Dropdown>
                  </Menu>
               </div>
            </Stack>
         </Group>
      )
   }
}

export default Head
