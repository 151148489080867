import { usePosition } from '../tools/usePosition'
import moment from 'moment-timezone'
import { Badge } from '@mantine/core'

function BarLocation() {
   const { latitude, longitude, timestamp, accuracy } = usePosition()

   return (
      <>
         <Badge color='teal' variant='light' size='xs' radius='sm' style={{ marginTop: '20px' }}>
            <b>Lat</b> {latitude}, <b>Long</b> {longitude}, <b>Acc</b> {accuracy && `${accuracy}M.`}, <b>Time</b> {moment(timestamp).format('HH:mm:ss')}{' '}
         </Badge>
      </>
   )
}

export default BarLocation
