import React, { useRef, useContext } from 'react'
import axios from 'axios'
import { UserContext } from '../../_context'

const apiServer = process.env.REACT_APP_API_URL

function Upload({ setUploadedFile, src, loc }) {
	const file = useRef()
	const img = useRef()
	const [user, setUser] = useContext(UserContext)

	// if src extention is .pdf, show pdf icon
	const isPdf = src.includes('.pdf')

	return (
		// if pdf, show pdf icon
		// if not, show image
		<div className='uploader'>
			{isPdf ? (
				<>
					<img ref={img} src='/iconPdf.png' className='img' alt='PDF' onClick={() => file.current.click()} />
					<input type='file' ref={file} onChange={submitUpload} style={{ display: 'none' }}></input>
				</>
			) : (
				<>
					<img ref={img} src={src} className='img' alt='img' onClick={() => file.current.click()} />
					<input type='file' ref={file} onChange={submitUpload} style={{ display: 'none' }}></input>
				</>
			)}
		</div>
	)

	function submitUpload() {
		var formData = new FormData()

		formData.append('photo', file.current.files[0])

		axios
			.post(apiServer + '/upload/' + loc, formData, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${user.token}` } })

			.then(function (response) {
				img.current.src = response.data.file.location

				let updatedUser = { ...user, idPhoto: response.data.userphotos }

				setUser(updatedUser)
				localStorage.setItem('user', JSON.stringify(updatedUser)) // save user to local storage
			})

			.catch(function (error) {
				console.log('failed')
			})
	}
} // end component

Upload.defaultProps = '/uploads/default.jpg'

export default Upload
