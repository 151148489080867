import React from 'react'

function cityIndicatior() {
	const city = sessionStorage.getItem('city') ? sessionStorage.getItem('city') : 'city'
	const vehicle = localStorage.getItem('vehicle') ? localStorage.getItem('vehicle') : 'vehicle'

	return (
		<div
			style={{
				position: 'fixed',
				bottom: '0px',
				left: '0px',
				color: '#fff',
				backgroundColor: 'rgba(0,0,0,0.2)',
				padding: '5px',
				borderRadius: '5px',
				margin: '5px',
			}}>
			<small>
				{city} - {vehicle}
			</small>
		</div>
	)
}

export default cityIndicatior
