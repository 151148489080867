import React, { useContext, useState, useRef } from 'react'

import Head from './tools/head'
import { UserContext } from '../_context'
import axios from 'axios'
 

import { useTranslation } from 'react-i18next'
import {  Text, Stack,  Card, Button, Center,  } from '@mantine/core'



import { useParams } from 'react-router-dom'
  
const Buttons = ({ userType }) => {

	const buttonRef = useRef()

	const params = useParams() 

	const { t } = useTranslation()
	
	const apiServer = process.env.REACT_APP_API_URL

	const [user] = useContext(UserContext)

	const config = { headers: { Authorization: `Bearer ${user.token}` }, }

	const [message, setMessage] = useState(null)

 
	// connect to api and get button data
    

	const  onAccept = () => {

	   // api route /api/qrbuttonOrder1/:id

	      axios.post(apiServer + `/qrbuttonOrder${params.no}/` + params.id,   config)

	   .then((response) => { 
		
		setMessage(response.data)

	    // delete the buttonref

		buttonRef.current.remove()

		// close current window

	
       

    setTimeout(() => {
		window.open("about:blank", "_self");
		window.close();
	}, 1000);


	   })

		.catch((error) => { setMessage(error.response.data); })

	}
	 
	return (
		<>
			{userType === 'booker' ? <Head type='mainbooker' /> : <Head type='main' />}

			<Card spacing='md' withBorder  p='md'>
				<Stack direction='row' spacing={10}>
					 

				<Button size='xl' ref={buttonRef} onClick={onAccept}>Accept Mission</Button>
					 
              <Center><Text c="dimmed">{message}</Text></Center>
					 
				</Stack>
			</Card>
		</>
	)
}

export default Buttons
