import { PasswordInput, Text, Group } from '@mantine/core'

function ForgotPasswordInput({ className, style, ...others }) {
   return (
      <div className={className} style={style}>
         <Group position='apart' mb={5}>
            <Text component='label' htmlFor='your-password' size='sm' weight={500}></Text>
         </Group>
         <PasswordInput placeholder='Password' id='your-password' {...others} />
      </div>
   )
}

export default ForgotPasswordInput
