import { useState } from "react";
import { IconUserCheck, IconMailOpened, IconShieldCheck, IconCircleCheck } from "@tabler/icons-react";
import { Stepper, Badge, Stack, Alert } from "@mantine/core";
import { useTranslation } from "react-i18next";
function StepperMissions({ mission, hidden }) {
  const { t } = useTranslation();
  let activeStep = 0;

  const icon = <IconShieldCheck />;

  switch (mission.status) {
    case "available":
      activeStep = 0;
      break;
    case "booked":
      activeStep = 1;
      break;
    case "inTransit":
      activeStep = 2;
      break;
    case "delivered":
      activeStep = 3;
      break;
    default:
      break;
  }

  const steps = [
    { label: "Available", value: 0, icon: <IconUserCheck /> },
    { label: "Booked", value: 0, icon: <IconUserCheck /> },
    { label: "InTransit", value: activeStep === 2 ? 1 : 0, icon: <IconUserCheck /> },
    { label: "Delivered", value: 0, icon: <IconUserCheck /> },
  ];

  const [active, setActive] = useState(1);
  if (hidden)
    return (
      <Badge size="xl" radius={"md"} tt="uppercase">
        {t(mission.status)}
      </Badge>
    );
  return (
    <Stack>
      {mission.status === "underReview" ? (
        <Alert variant="light" color="orange" title={t("underReview")} icon={icon}>
          {t("underReviewDetails")}
        </Alert>
      ):(
		<Stepper color="red" radius="lg" size="md" active={activeStep + 1} onStepClick={setActive} completedIcon={<IconCircleCheck />} breakpoint="sm">
        {steps.map((step) => (
          <Stepper.Step key={1} icon={step.icon} label={t(step.label)} loading={step.value} />
        ))}
      </Stepper>
	  )}

      
    </Stack>
  );
}

export default StepperMissions;
