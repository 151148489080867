import { MdOutlineFastfood } from 'react-icons/md'

import { RiTempHotFill } from 'react-icons/ri'

import { IoDocumentsOutline } from 'react-icons/io5'
import { GiShatteredGlass, GiThermometerCold } from 'react-icons/gi'

import { FaShippingFast } from 'react-icons/fa'

import { GoLaw } from 'react-icons/go'

const size = 26

export default {
   food: <MdOutlineFastfood size={size} />,
   warm: <RiTempHotFill size={size} />,
   keepCold: <GiThermometerCold size={size} />,
   documents: <IoDocumentsOutline size={size} />,
   fragile: <GiShatteredGlass size={size} />,
   urgent: <FaShippingFast size={size} />,
   critical: <GoLaw size={size} />
}
