import React from 'react'
import { useState } from 'react'

import { Tabs, Space, Text, ActionIcon, useMantineTheme, Center } from '@mantine/core'

import { TabContext, TabPanel } from '@mui/lab'

import { IoIosArrowBack } from 'react-icons/io'

import { Link } from 'react-router-dom'

import logo from '../../assets/logo.svg'

import LoginForm from './loginForm'
import RegisterForm from './registerForm'

import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'

import { Card, Stack, Flex } from '@mantine/core'

const Login = ({ type }) => {
	const navigate = useNavigate()

	const { colorScheme, theme } = useMantineTheme()

	console.log(colorScheme)

	const [value, setValue] = useState('login')

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	let html

	const { t } = useTranslation()

	return (
		<Flex mih={'100vh'} justify='center' direction={'column'}>
			<div className={colorScheme === 'light' ? 'header__background_light' : 'header__background_dark'}></div>

			<Card>
				<ActionIcon variant='filled' color='red' size={50} onClick={(e) => navigate('/')} className='back'>
					<IoIosArrowBack size={50} />
				</ActionIcon>

				<Stack align='center'>
					<img src={logo} className='App-logo' alt='logo' width='340px' />
					<Stack align='center'>
						<Text c='dimmed'>{type === 'poster' ? t('purchase') : t('dmisson')}</Text>
					</Stack>
					<Space size='lg' />
				</Stack>
				<Tabs color='red' radius='md' defaultValue='gallery' value={value} onTabChange={setValue}>
					<Tabs.List grow style={{ height: 70 }}>
						<Tabs.Tab value='login'>{t('login')}</Tabs.Tab>
						<Tabs.Tab value='register'>{t('register')}</Tabs.Tab>
					</Tabs.List>

					<Tabs.Panel value='login'>
						<LoginForm userType={type} />
					</Tabs.Panel>
					<Tabs.Panel value='register'>
						<RegisterForm userType={type} />
					</Tabs.Panel>
				</Tabs>
				<TabContext value={value} sx='width:100px'>
					<TabPanel value='login'></TabPanel>
					<TabPanel value='register'></TabPanel>
				</TabContext>
			</Card>
		</Flex>
	)
}
export default Login
