import React, { useState } from 'react'
import HeatMap from '@uiw/react-heat-map'
import { SegmentedControl, Stack, Card, useMantineColorScheme } from '@mantine/core'

import moment from 'moment'

const Demo = ({ value, select, setSelected }) => {
   const { colorScheme } = useMantineColorScheme()

   const nowyear = moment().format('YYYY')

   const yearBefore = moment(nowyear).subtract(1, 'years').format('YYYY').toString()
   const yearBefore2 = moment(nowyear)?.subtract(2, 'years').format('YYYY').toString()

   const [year, setYear] = useState(nowyear)

   return (
      <Card withBorder className={'mainSize'}>
         <Stack>
            <HeatMap
               style={{ color: colorScheme === 'light' ? '#888' : '#aaa' }}
               value={value}
               className='mainSize'
               startDate={new Date(year + '/01/01')}
               legendCellSize={0}
               width={'100%'}
               height={115}
               weekLabels={''}
               panelColors={{
                  0: colorScheme === 'light' ? '#ffe3e0' : '#333',
                  2: colorScheme === 'light' ? '#fbc3bc' : '#666',
                  4: colorScheme === 'light' ? '#f7a399' : '#777',
                  10: colorScheme === 'light' ? '#f38375' : '#888',
                  20: colorScheme === 'light' ? '#d52941' : '#999',
                  30: colorScheme === 'light' ? '#990d35' : '#fff'
               }}
               rectRender={(props, data) => {
                  if (select !== '') {
                     props.opacity = data.date === select ? 1 : 0.45
                  }
                  return (
                     <rect
                        {...props}
                        onClick={() => {
                           setSelected(data.date)
                        }}
                     />
                  )
               }}
            />

            <SegmentedControl
               value={year}
               onChange={(value) => setYear(value)}
               data={[
                  { label: nowyear, value: nowyear },
                  { label: yearBefore, value: yearBefore },
                  { label: yearBefore2, value: yearBefore2 }
               ]}
            />
         </Stack>
      </Card>
   )
}
export default Demo
