import React, { useContext, useState } from 'react'

import Head from './tools/head'
import { UserContext } from '../_context'
import axios from 'axios'

import { useFormik } from 'formik'
import * as yup from 'yup'

import { useTranslation } from 'react-i18next'
import { Group, Text, Stack, Card, Anchor, createStyles, Title, Button, Divider, Select } from '@mantine/core'

import PhoneInput from 'react-phone-input-2'

import { TextInput } from '@mantine/core'

import { BsCreditCard2Front } from 'react-icons/bs'
import { IconChevronDown } from '@tabler/icons-react'
import Upload from './tools/upload'
import CitySelect from './tools/citiSelect'
import get from 'lodash/get'
import 'react-phone-input-2/lib/material.css'

import { useMantineTheme } from '@mantine/core'

const Profile = ({ userType }) => {
   const { colorScheme } = useMantineTheme()

   const { t, i18n } = useTranslation()
   const apiServer = process.env.REACT_APP_API_URL

   const [user, setUser] = useContext(UserContext)

   const [city, setCity] = useState(sessionStorage.getItem('city') || '')

   const useStyles = createStyles((theme) => ({}))

   const validationSchema = yup.object({
      email: yup.string('Enter a email').min(3, 'Title should be of minimum 3 characters length').email('Must be a valid email').required('E-mail is required'),

      name: yup.string('Enter your description').min(4, 'Name should be of minimum 4 characters length').required(t('namereq')),

      phone: yup.string('Enter your description').min(4, 'Phone number should be of minimum 4 characters length').required('Phone number name is required'),
      city: yup.string('Enter your description').required(t('cityreq'))
   })

   const [waiting, setWaiting] = useState(false)
   const [invalid, setInvalid] = useState('.')

   const config = {
      headers: { Authorization: `Bearer ${user.token}` }
   }

   const update = (obj) => {
      setWaiting(true)

      axios
         .post(apiServer + '/user/update/', obj, config)
         .then((response) => {
            let newUser = { ...user, ...obj }

            setUser(newUser)

            localStorage.setItem('user', JSON.stringify(newUser)) // save user to local storage
         })
         .catch((error) => {
            setInvalid('Can not update!')
            setTimeout(() => setInvalid('.'), 3 * 1000)
         })
         .finally(() => setWaiting(false))
   }

   const initVals = { name: user.name, email: user.email, phone: user.phone, city: user.city }

   const formik = useFormik({
      enableReinitialize: true,
      validationSchema: validationSchema,
      initialValues: initVals,

      onSubmit: (values, actions) => {
         user.city = city
         update(values)
      }
   })

   const [cities, setCities] = useState([])

   const { classes } = useStyles()

   // LANGUAGE RELATED

   function objectFlip(obj) {
      const ret = {}
      Object.keys(obj).forEach((key) => {
         ret[obj[key]] = key
      })
      return ret
   }

   const locdata = [
      { label: 'English', value: 'en' },
      { label: 'Français', value: 'fr' }
   ]

   function changelang(e) {
      i18n.changeLanguage(e)
   }

   /// CITY RELATED

   const maped = cities.map((city) => city.text)

   /* const [city, setCity] = useState('') */

   return (
      <>
         {userType === 'booker' ? <Head type='mainbooker' /> : <Head type='main' />}

         <Card spacing='md' withBorder>
            <Stack direction='row' spacing={10}>
               <Group position='apart'>
                  <Title order={6}>{t('p_title')} </Title>
                  <Text className={classes.title}></Text>

                  <Anchor href={'/logged/' + userType + '/billing'} size='xs' color='dimmed' sx={{ lineHeight: 1 }}>
                     <Group position='right' spacing={5} align='center'>
                        <BsCreditCard2Front />
                     </Group>
                     {invalid ? <div className='invalid'>{invalid}</div> : <Text tt='uppercase'>{t('h_personal')}</Text>}
                  </Anchor>
               </Group>

               <form onSubmit={formik.handleSubmit}>
                  <Stack>
                     <Upload src={get(user, 'idPhoto.tradeFront', '/uploads/front.png')} loc='tradeFront' />
                     <Divider />
                     <Group grow>
                        {/* // put user avatar  */}

                        <TextInput
                           withAsterisk
                           id='name'
                           label={t('name')}
                           value={formik.values.name}
                           onChange={formik.handleChange}
                           error={formik.touched.name && Boolean(formik.errors.name)}
                           helperText={formik.touched.name && formik.errors.name}
                           inputProps={{
                              maxLength: 30
                           }}
                        />
                        <TextInput withAsterisk id='email' disabled label={t('email')} value={formik.values.email} onChange={formik.handleChange} error={formik.touched.email && Boolean(formik.errors.email)} helperText={formik.touched.email && formik.errors.email} />
                     </Group>
                     <Group grow>
                        <Stack spacing={20}>
                           {/* This part is for phone input with county code selector */}

                           <Text size={14}>{t('YourPhone')}</Text>
                           <PhoneInput
                              specialLabel=''
                              id='phone'
                              buttonStyle={{
                                 borderRadius: '10px',
                                 border: 'none',
                                 background: 'none',
                                 fontSize: '12px',

                                 color: colorScheme === 'dark' ? '#ccc' : '#333'
                              }}
                              inputStyle={{
                                 borderRadius: '10px',
                                 fontSize: '12px',
                                 width: '100%',
                                 color: colorScheme === 'dark' ? '#ccc' : '#333',
                                 borderColor: colorScheme === 'dark' ? '#333' : '#cacaca',
                                 backgroundColor: colorScheme === 'dark' ? '#25262b' : '#fff'
                              }}
                              defaultCountry={'ch'}
                              enableSearch={true}
                              value={formik.values.phone}
                              onChange={(w) => {
                                 formik.setFieldValue('phone', w)
                              }}
                              error={formik.touched.phone && Boolean(formik.errors.phone)}
                              helperText={formik.touched.phone && formik.errors.phone}
                           />
                           <CitySelect selectedCity={city} setSelectedCity={setCity} size={'lg'} />
                        </Stack>
                     </Group>

                     <Select rightSection={<IconChevronDown size='1rem' />} rightSectionWidth={40} defaultValue={i18n.language} onChange={changelang} data={locdata} label={t('language')} description={t('preferedLanguage')} size='lg' />

                     <Button
                        size='xl'
                        onClick={(e) => {
                           formik.handleSubmit()
                        }}
                        loading={waiting}>
                        {t('p_update')}
                     </Button>
                  </Stack>
               </form>
            </Stack>
         </Card>
      </>
   )
}

export default Profile
