import React from 'react'
import { useParams } from 'react-router-dom'
import { usePosition } from './tools/usePosition'

import Map from './tools/mapD'


function Locator({ buttonID }) {
	let params = useParams()

	const { latitude, longitude} = usePosition()

	const [marker, setMarker] = React.useState({
		longitude: longitude,
		latitude: latitude,
	})

	const [address, setAddress] = React.useState('')

	const fromFrom = (result) => {
		console.log(result)
		if (result.result.place_name) setAddress(result.result?.place_name)
		result.result.place_name && setMarker(result.result)
	}

	return (
		<>
			<h1>{address}</h1>
			{latitude && (
				<Map
					width='100%'
					height='300px'
					key='1'
					func={fromFrom}
					centr={{
						latitude: latitude,
						longitude: longitude,
						zoom: 16,
					}}
				/>
			)}
			locator {params.buttonID}
		</>
	)
}

export default Locator
