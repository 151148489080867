import React, { useState } from 'react'

import { Button, Text, Card, ActionIcon, TextInput, Stack, Alert, Flex } from '@mantine/core'

import { BsFillShieldLockFill } from 'react-icons/bs'

import axios from 'axios'

import { IoIosArrowBack } from 'react-icons/io'

import { useNavigate } from 'react-router-dom'

import logo from '../../assets/logo.svg'

import { useFormik } from 'formik'
import * as yup from 'yup'

import { useTranslation } from 'react-i18next'

const apiServer = process.env.REACT_APP_API_URL

const Forgot = ({ type }) => {
	const { t } = useTranslation()
	const [submit, setSubmit] = useState(false)

	const navigate = useNavigate()

	const validationSchema = yup.object({
		email: yup.string('Enter a email').min(3, 'Title should be of minimum 3 characters length').email('Must be a valid email').required(t('emailreq')),
	})

	const sendMail = (obj) => {
		axios
			.post(apiServer + '/forgot', obj)
			.then((response) => {})
			.catch((error) => console.log(error))
	}

	const initVals = { email: '' }

	const formik = useFormik({
		enableReinitialize: true,
		validationSchema: validationSchema,
		initialValues: initVals,

		onSubmit: (values) => {
			setSubmit(true)
			sendMail(values)
		},
	})

	const It1 = () => (
		<>
			<Stack align='center'>
				<img src={logo} className='App-logo' alt='logo' width={340} />

				<Text weight={700}>Forgot Password?</Text>
			</Stack>
			<ActionIcon size={50} onClick={(e) => navigate(-1)} className='back' variant='light'>
				<IoIosArrowBack size={50} />
			</ActionIcon>
		</>
	)

	if (submit) {
		return (
			<Flex mih={'98vh'} justify='center' direction={'column'}>
				<It1 />
				<Card>
					<Stack>
						<Stack direction='column' justifyContent='center' alignItems='stretch' maxWidth='640px' padding='20px' spacing={2}>
							We e-mailed you the instructions to reset your password. Please Check your e-mail. For security reasons, we do NOT store your password. So rest assured that
							we will never send your password via email.
							<Button onClick={(e) => navigate(-1)}>Back To Login</Button>
						</Stack>
					</Stack>
				</Card>
			</Flex>
		)
	}
	return (
		<Flex mih={'98vh'} justify='center' direction={'column'}>
			<Card>
				<Stack spacing={20} style={{ margin: 30 }}>
					<It1 />

					<TextInput
						size='lg'
						id='email'
						label={t('email')}
						description={t('ftex1')}
						placeholder='E-Mail'
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						helperText={formik.touched.email && formik.errors.email}
					/>
					<Alert icon={<BsFillShieldLockFill size={50} />} title='Privacy Notification' color='gray' radius='lg'>
						<Text c='dimmed'>{t('ftex2')}</Text>
					</Alert>

					<Button size='xl' onClick={formik.handleSubmit}>
						{t('ftex3')}
					</Button>
				</Stack>
			</Card>
		</Flex>
	)
}
export default Forgot
