import React, { useContext } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'

import { UserContext } from '../_context'

import Welcome from './welcome'
import Login from './login/login'
import Forgot from './login/forgot'

import ResetPassword from './login/resetPassword'

import Log from './poster/welcome'
import Mission from './poster/mission'

import Statements from './statements'

import Profile from './profile' /// CURRENT WORKING ON
import Billing from './billing'

import EasyQr from './poster/qrButtons' /// CURRENT WORKING ON

import Dashboard from './booker/dash'

import MissionDetails from './mission-details'

import BookerWelcome from './booker/welcome'

import Locator from './locator'

import Buttons from './buttons'

function AppRoutes() {
   const [user] = useContext(UserContext)

   var auth = user.type ? user.type : false

   function PrivatePoster({ children }) {
      return auth ? children : <Navigate to='/poster' />
   }

   function PrivateBooker({ children }) {
      return auth ? children : <Navigate to='/booker' />
   }

   function IsNotLoggedPoster({ children }) {
      return auth ? <Navigate to={'/logged/poster'} /> : children
   }

   function IsNotLoggedBooker({ children }) {
      return auth ? <Navigate to={'/logged/booker'} /> : children
   }

   return (
      <Router>
         <Routes>
            <Route path='/' element={<Welcome />} />
            <Route path='/en' element={<Welcome lng='en' />} />
            <Route path='/fr' element={<Welcome lng='fr' />} />
            <Route path='/location/:buttonID' element={<Locator />} />
            <Route
               path='/poster'
               element={
                  <IsNotLoggedPoster>
                     <Login type='poster' />
                  </IsNotLoggedPoster>
               }
            />
            <Route
               path='/booker'
               element={
                  <IsNotLoggedBooker>
                     <Login type='booker' />
                  </IsNotLoggedBooker>
               }
            />
            <Route path='/forgot' element={<Forgot />} />
            <Route path='/password-reset/:token' element={<ResetPassword />} />
            {
               //private poster routes
            }
            <Route
               path='/logged/poster'
               element={
                  <PrivatePoster>
                     <Log />
                  </PrivatePoster>
               }
            />
            <Route
               path='/buttons/:id/:no'
               element={
                  <PrivatePoster>
                     <Buttons userType='poster' />
                  </PrivatePoster>
               }
            />
            <Route
               path='/logged/poster/newmission'
               element={
                  <PrivatePoster>
                     <Mission />
                  </PrivatePoster>
               }
            />
            <Route
               path='/logged/poster/statements'
               element={
                  <PrivatePoster>
                     <Statements userType='poster' />
                  </PrivatePoster>
               }
            />
            <Route
               path='/logged/poster/profile'
               element={
                  <PrivatePoster>
                     <Profile userType='poster' />
                  </PrivatePoster>
               }
            />
            <Route
               path='/logged/poster/easyButtons'
               element={
                  <PrivatePoster>
                     <EasyQr type='BN' />
                  </PrivatePoster>
               }
            />
            <Route
               path='/logged/poster/easyQr'
               element={
                  <PrivatePoster>
                     <EasyQr type='QR' />
                  </PrivatePoster>
               }
            />
            <Route
               path='/logged/poster/billing'
               element={
                  <PrivatePoster>
                     <Billing userType='poster' />
                  </PrivatePoster>
               }
            />
            {
               //private booker routes
            }
            <Route
               path='/logged/booker'
               element={
                  <PrivateBooker>
                     <BookerWelcome />
                  </PrivateBooker>
               }
            />
            <Route
               path='/logged/booker/profile'
               element={
                  <PrivateBooker>
                     <Profile userType='booker' />
                  </PrivateBooker>
               }
            />
            <Route
               path='/logged/booker/billing'
               element={
                  <PrivateBooker>
                     <Billing userType='booker' />
                  </PrivateBooker>
               }
            />
            <Route
               path='/logged/booker/dashboard'
               element={
                  <PrivateBooker>
                     <Dashboard />
                  </PrivateBooker>
               }
            />{' '}
            <Route
               path='/logged/booker/statement/*'
               element={
                  <PrivateBooker>
                     <Statements userType='booker' />
                  </PrivateBooker>
               }
            />
            <Route path='/mission/:id' element={<MissionDetails />} />
            <Route path='/404' component={404} />
            <Route path='*' element={<Navigate replace to='/' />} />
         </Routes>
      </Router>
   )
}

export default AppRoutes
