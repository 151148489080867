import React, { useState, useRef, useCallback, useEffect } from 'react'

import MapGL, { Marker } from 'react-map-gl'

import '../tools/mapbox-gl-geocoder.css'

import logo from '../../assets/marker.png'

// Please be a decent human and don't abuse my Mapbox API token.
// If you fork this sandbox, replace my API token with your own.
// Ways to set Mapbox token: https://uber.github.io/react-map-gl/#/Documentation/getting-started/about-mapbox-tokens

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_KEY

const Map = ({ func, centr }) => {
	const [adder, setAdder] = useState(0)

	const added = () => {
		if (adder > 0.02) {
			setAdder(0)
		}

		setAdder((old) => old + 0.00001)
	}

	useEffect(() => {
		const s = setInterval(() => {
			added()
		}, 1)

		return () => clearInterval(s)
	})

	const [viewport, setViewport] = useState(centr)

	const mapRef = useRef()

	const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), [])

	// if you are happy with Geocoder default settings, you can just use handleViewportChange directly

	const [settings] = useState({
		dragPan: false,
		dragRotate: false,
		scrollZoom: false,
		touchZoom: false,
		touchRotate: false,
		keyboard: false,
		doubleClickZoom: false,
		width: '100%',
		height: '100%',
	})

	return (
		<div style={{ height: '400px', width: '100%' }}>
			<MapGL ref={mapRef} {...viewport} {...settings} onViewportChange={handleViewportChange} mapboxApiAccessToken={MAPBOX_TOKEN}>
				<Marker longitude={centr.longitude + adder} latitude={centr.latitude} draggable anchor='center-center'>
					<img src={logo} alt='marker' />
				</Marker>
			</MapGL>
		</div>
	)
}

export default Map
