import { createStyles, Badge, Group, Title, Text, Card, SimpleGrid, Container, rem } from '@mantine/core'

import { BsMailbox } from 'react-icons/bs'

import { useTranslation } from 'react-i18next'

import { useNavigate } from 'react-router-dom'

import { MdOutlineSportsMotorsports } from 'react-icons/md'

const useStyles = createStyles((theme) => ({
	title: {
		fontSize: rem(34),
		fontWeight: 900,

		[theme.fn.smallerThan('sm')]: {
			fontSize: rem(24),
		},
	},

	description: {
		maxWidth: 600,
		margin: 'auto',

		'&::after': {
			content: '""',
			display: 'block',

			width: rem(45),
			height: rem(2),
			marginTop: theme.spacing.sm,
			marginLeft: 'auto',
			marginRight: 'auto',
			// non selectable
		},
	},

	card: {
		backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0],
		border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]}`,
		'&:hover': {
			boxShadow: theme.shadows.lg,
			transform: 'scale(1.05)',
			userSelect: 'none',

			cursor: 'pointer',
		},
	},

	nomission: {
		backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0],
		border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]}`,
		'&::after': {
			content: '""',
			display: 'block',
			backgroundColor: theme.fn.primaryColor(),
			width: rem(45),
			height: rem(2),
			marginTop: theme.spacing.sm,
		},
	},

	cardTitle: {
		'&::after': {
			content: '""',
			display: 'block',
			backgroundColor: theme.fn.primaryColor(),
			width: rem(45),
			height: rem(2),
			marginTop: theme.spacing.sm,
		},
	},
}))

function HomeButtons() {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { classes, theme } = useStyles()
	const mockdata = [
		{
			title: t('poster'),
			description: t('dmisson'),
			icon: BsMailbox,
			link: '/poster',
		},
		{
			title: t('booker'),
			description: t('purchase'),
			icon: MdOutlineSportsMotorsports,
			link: '/booker',
		},
	]

	const features = mockdata.map((feature) => (
		<Card
			key={feature.title}
			shadow='md'
			radius='md'
			className={classes.card}
			padding='xl'
			position='center'
			align='center'
			onClick={(e) => {
				console.log('click')
				navigate(feature.link)
			}}>
			<feature.icon size={rem(50)} stroke={2} color={theme.fn.primaryColor()} />
			<Text fz='lg' fw={500} className={classes.cardTitle} mt='md'>
				{feature.title}
			</Text>
			<Text fz='sm' c='dimmed' mt='sm'>
				{feature.description}
			</Text>
		</Card>
	))

	return (
		<Container size='lg' py='xl'>
			<Group position='center'>
				<Badge variant='filled' size='lg'>
					The largest delivery mission community!
				</Badge>
			</Group>

			<Title order={2} className={classes.title} ta='center' mt='sm'>
				{t('tag')}
			</Title>

			<Text className={classes.description} ta='center' mt='md'></Text>

			<SimpleGrid cols={2} spacing='xl' mt={50}>
				{features}
			</SimpleGrid>
		</Container>
	)
}
export default HomeButtons
