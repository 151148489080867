import React, { useContext, useState, useEffect } from 'react'

import { useMantineTheme } from '@mantine/core'

import { useTranslation, Trans } from 'react-i18next'

import { useNavigate } from 'react-router-dom'
import { UserContext, SocketContext } from '../../_context'

import Head from '../tools/head'

import useSWR from 'swr'

import get from 'lodash/get'

import List from '../tools/postslist'
import axios from 'axios'

import Sticky from 'react-sticky-el'

import { useMediaQuery } from '@mantine/hooks'

import NoMissions from './helpers/nomission'

import NoWaiting from './helpers/nowaiting'

import { Stack, Text, Group, Space, Modal } from '@mantine/core'

import DetailsPop from './detailspop'
import PendingPop from './pendingpop'

import NoConnection from '../tools/noconnection'

import ReactGA from 'react-ga'

import { Card, Tabs, Badge } from '@mantine/core'

import BarLocation from './barLocation'

import CityIndicator from './cityIndicator'

import Loading from '../tools/loading'

import { useHotkeys } from '@mantine/hooks'

const Dash = () => {
   const isMobile = useMediaQuery('(max-width: 50em)')
   const theme = useMantineTheme()
   // const { latitude, longitude, timestamp, accuracy } = usePosition()

   const socket = useContext(SocketContext)
   const [user] = useContext(UserContext)
   const { t } = useTranslation()

   const navigate = useNavigate()

   const [noOfMissions, setNoOfMissions] = useState('-')

   const [vehicle] = useState(localStorage.getItem('vehicle') || 'car')
   const city = useState(sessionStorage.getItem('city') || 'Geneva')
   console.log(city)
   const setSelected = (e) => {
      setSelectedMission(e)
      setShow(true)
   }

   //const [user] = useContext(UserContext)
   const [value, setValue] = useState('first')
   const [show, setShow] = useState(false)

   const [bookedShow, setBookedShow] = useState(false)

   const [selectedMission, setSelectedMission] = useState(null)

   const [stripeConnect, setStripeConnect] = useState(false)

   const config = {
      headers: { Authorization: `Bearer ${user.token}` }
   }

   const getLink = (e) => {
      axios.get(apiServer + '/stripe/connect', config).then((response) => {
         console.log(response.data)

         window.open(response.data.stripeLink, '_blank')
      })
   }

   useHotkeys([
      [
         'space',
         () => {
            switch (value) {
               case 'first':
                  setValue('second')
                  break
               case 'second':
                  setValue('third')
                  break
               case 'third':
                  setValue('first')
                  break
               default:
                  setValue('first')
            }
         }
      ],
      ['enter', () => console.log('Rick roll')]
   ])

   useEffect(() => {
      const checkOnboardingApi = async () => {
         await axios
            .get(apiServer + '/stripe/checkOnboarding', config)

            .then((response) => {
               console.log(response.data)
               setStripeConnect(response.data)
            })
      }

      checkOnboardingApi()
   }, [])

   let noBank = () => (
      <Card
         onClick={() => {
            navigate('/logged/booker/billing')
         }}>
         {t('addMissionPl')}
         <br />
         {t('addMissionPl1')}
      </Card>
   )

   noBank = false

   let noStripeConnect = ''

   if (stripeConnect.error) {
      noStripeConnect = (
         <Card withBorder style={{ margin: '20px 0px 20px 0px' }}>
            <Stack onClick={getLink} style={{ textAlign: 'center' }}>
               <Text size={'xs'}>
                  <Trans i18nKey='onboard_popup' />
               </Text>
            </Stack>
         </Card>
      )
   }

   if (stripeConnect.error === false) {
   }

   // CONNECT TO API AND GET MISSION LIST

   const apiServer = process.env.REACT_APP_API_URL
   const fetcher = (url) => axios.post(url, { city: city, vehicle: vehicle }, config).then((res) => res.data)

   const { data, mutate, error } = useSWR(apiServer + '/booker/missions', fetcher, {
      onSuccess: (data) => {
         setNoOfMissions(data.length?.active)
      }
   })

   function displayNotification() {
      if ('Notification' in window && Notification.permission === 'granted') {
         navigator.serviceWorker.getRegistration().then(function (reg) {
            console.log('displayNotification')
            reg.showNotification('New Mission Posted On Pedallo!', {
               badge: 'https://platform.pedallo.com/icon.png',
               body: 'Click to see the mission',
               icon: 'https://platform.pedallo.com/logo.png',
               tag: 'mission-posted',
               renotify: true,
               vibrate: [300, 100, 400]
            })
         })
      }
   }

   ReactGA.initialize(process.env.REACT_APP_GA_ID)

   useEffect(() => {
      ReactGA.event({
         category: 'Booker',
         action: 'Booker /' + user.name + '/' + user.vehicle + '/' + user.city,
         label: user.name
      })

      if ('Notification' in window && Notification.permission !== 'granted') {
         Notification.requestPermission(function (status) {
            console.log('Notification permission status:', status)
         })
      } else if ('serviceWorker' in navigator) {
         navigator.serviceWorker.register('/sw.js').then(function () {
            console.log('Service worker is registered.')
            if (!navigator.serviceWorker.controller) {
               console.log('Service worker needs to be activated.')
               //location.reload(); // you may choose to reload to sync up with a version change of the service worker
            } else {
               console.log('Service worker is active.')
               // setTimeout(function() { serviceWorkerVersionCheck(true); },250); // you might need to check it's version
            }
         })
      } else {
         // service worker is not supported so the user cant receive push notifications
      }

      console.log('app running')
   }, [user.name, user.vehicle, user.city, user.email])

   useEffect(() => {
      console.log('soketID', socket.id)

      // do a database query to see if the user is logged in active users as booker to track their location live and update the map
      // if they are logged in as booker, then send their socket id to the server to be stored in the database
      // if they are not logged in as booker, then send their socket id to the server to be removed from the database

      socket.emit('booker', { socketID: socket.id, token: user.token })

      socket.on('missionUpdate', (data) => {
         mutate()
         if (data.io === 'new') {
            displayNotification()
         }
      })
   }, [socket, mutate])

   if (error) return <NoConnection />
   if (!data) return <Loading />

   function closeModal(e) {
      setBookedShow(false)
      setShow(false)
   }

   const listOfPendings1 = data['active'] && data['active'].map((mission) => <List mission={mission} key={mission._id} setSelectedMission={setSelected} />)

   const listOfDelivered = data['delivered'] && data['delivered'].map((mission) => <List mission={mission} key={mission._id} setSelectedMission={setSelected} />)

   const listOfUnderReview = data['underReview'] && data['underReview'].map((mission) => <List mission={mission} key={mission._id} setSelectedMission={setSelected} />)

   // conbine list of pending and under review

   const listOfPendings = [...listOfPendings1, ...listOfUnderReview]

   // filter list of missions by vehicle type

   const filteriMissions =
      data.missions?.length > 0
         ? data.missions
              .filter((mission) => {
                 return mission.deliveryMode === 'all' || mission.deliveryMode === user.vehicle
              })

              .map((mission, key) => <List mission={mission} key={mission._id} setSelectedMission={setSelected} />)
         : false

   return (
      // todo:this needs to be a localized english version /
      <>
         <Modal fullScreen={isMobile} size='xl' title={t('booked_mission')} open={bookedShow} onClose={() => setBookedShow(false)}>
            <Card>
               <h1>{t('booked_mission')}</h1> {t('booked_mission1')}
               <h3>{t('booked_mission2')}</h3>
            </Card>
         </Modal>
         <Modal fullScreen={isMobile} size={'xl'} title={t('p_title1')} opened={show} onClose={() => setShow(false)}>
            {selectedMission && selectedMission.status === 'available' ? <DetailsPop mission={selectedMission} closeFunc={closeModal} missionsNo={noOfMissions} /> : <PendingPop mission={selectedMission} closeFunc={closeModal} />}
         </Modal>

         <CityIndicator />

         <Head type='mainbooker' />

         {get(user, 'verified', true) ? null : noBank}
         {get(user, 'stripeConnectId', true) ? noStripeConnect : noStripeConnect}

         <Stack spacing={10}>
            <Card withBorder>
               <Tabs value={value} color='red' radius='md' variant='pills' onTabChange={setValue}>
                  <Stack spacing={10}>
                     <Sticky>
                        <Tabs.List position='center' grow style={{ height: '100px', backgroundColor: theme.colorScheme === 'dark' ? '#222' : '#fff' }}>
                           <Tabs.Tab value='first'>
                              <Group>
                                 <Badge color='teal'>{listOfPendings?.length}</Badge>
                              </Group>
                              <Text tt='uppercase' size={10}>
                                 {t('b_act_mission')}
                              </Text>
                           </Tabs.Tab>
                           <Tabs.Tab value='second'>
                              <Group>
                                 <Badge color='teal'>{listOfDelivered?.length}</Badge>
                              </Group>
                              <Text tt='uppercase' size={10}>
                                 {t('b_delivered')}
                              </Text>
                           </Tabs.Tab>

                           <Tabs.Tab value='third' style={{ border: '1px solid #ffcc' }}>
                              <Group>
                                 <Badge color='teal'>{filteriMissions.length ? filteriMissions.length : '0'}</Badge>
                              </Group>
                              <Text tt='uppercase' size={10}>
                                 {t('b_book_mission')}
                              </Text>
                           </Tabs.Tab>
                        </Tabs.List>
                     </Sticky>
                     <Space size='xl' />

                     <Tabs.Panel value={'first'} index={0}>
                        <Stack spacing='lg' justify='center'>
                           {listOfPendings.length > 0 ? listOfPendings : <NoWaiting book={setValue} />}
                        </Stack>
                     </Tabs.Panel>

                     <Tabs.Panel value={'second'} index={1}>
                        <Stack spacing='lg' justify='center'>
                           {listOfDelivered.length > 0 ? listOfDelivered : <NoWaiting book={setValue} />}
                        </Stack>

                        <Stack spacing='lg' justify='center'></Stack>
                     </Tabs.Panel>

                     <Tabs.Panel value={'third'} index={1}>
                        <Stack spacing='lg' justify='center'>
                           {filteriMissions.length > 0 ? filteriMissions : <NoMissions book={setValue} />}
                        </Stack>
                     </Tabs.Panel>
                  </Stack>
               </Tabs>

               <BarLocation />
            </Card>
         </Stack>
      </>
   )
}

export default Dash
