import React from 'react'
import { Flex, Loader } from '@mantine/core'

function loading() {
	return (
		<Flex mih={'100vh'} justify='center' align='center' direction='row'>
			<Loader color='white' size='sm' />
		</Flex>
	)
}

export default loading
