import React, { useContext, useRef } from 'react'
import moment from 'moment'

import BookerBox from './helper/bookerBox'

import { MdPedalBike } from 'react-icons/md'
import { AiOutlineCar } from 'react-icons/ai'
import { GiFootprint } from 'react-icons/gi'

import { useTranslation } from 'react-i18next'
import { UserContext } from '../../_context'

import ReactToPrint from 'react-to-print'

import StepperMissions from '../tools/StepperMissions'

import { Button, Card, Stack, Text, Group, SimpleGrid, Tooltip, Badge } from '@mantine/core'

import { useClipboard } from '@mantine/hooks'
import axios from 'axios'
import AttachedRequirements from '../tools/AttachedRequirements'

import { useMediaQuery } from '@mantine/hooks'

import Logo from '../../assets/logo.svg'

import Rating from '../rating'

import Map from '../tools/mapc'

function Mission({ mission, setShow }) {
   const isMobile = useMediaQuery('(max-width: 50em)')

   const clipboard = useClipboard()

   /// Icon Size

   const componentRef = useRef()

   const apiServer = process.env.REACT_APP_API_URL

   const [user] = useContext(UserContext)

   const setRating = (value) => {
      console.log(value)

      // mission: req.body.mission,
      // 		poster:  req.body.poster,
      // 		booker: req.authUser._id,
      // 		posterRating: req.body.posterRating,
      // 		bookerRating: req.body.bookerRating,

      axios
         .post(apiServer + `/poster/rate`, { rating: value, mission: mission._id, booker: mission.booker }, config)
         .then((res) => {
            console.log(res)
         })
         .catch((err) => {
            console.log(err)
         })
   }

   const config = { headers: { Authorization: `Bearer ${user.token}` } }

   const { t } = useTranslation()
   const All = () => {
      return (
         <div>
            <AiOutlineCar />
            <MdPedalBike />
            <GiFootprint />
         </div>
      )
   }

   const cancel = (e) => {
      e.preventDefault()

      axios
         .get(apiServer + `/poster/cancel/${mission._id}`, config)
         .then((res) => {
            console.log(res)
            setShow(false)
         })
         .catch((err) => {
            console.log(err)
         })
   }

   return (
      <Stack ref={componentRef}>
         <StepperMissions hidden={isMobile} mission={mission} className='no-print' />

         <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1, spacing: 'md' }]}>
            <Stack>
               <Map mission={mission} width='100' height='100' />

               <Card withBorder>
                  <Group position='apart' align='top'>
                     <Stack spacing={5}>
                        {mission.immediate && <div className={'immediate'}> {mission.immediate ? 'immediate' : ''}</div>}
                        {mission.hourly && (
                           <Badge size='xs' color='yellow'>
                              {mission.hourly ? t('hourly') + ' +' + mission.hourlyRate + ' hrs.' : ''}
                           </Badge>
                        )}
                        <Text weight={700} size='xs' color='dimmed'>
                           {t('pickup_time')}
                        </Text>
                        <Text size='xs' weight={700}>
                           {moment(mission.pickupTime).format('DD.MM.YYYY HH:mm')}
                        </Text>
                        <Text size={7} color='dimmed'>
                           {moment(mission.pickupTime).calendar()}
                        </Text>

                        <Group position='apart' grow>
                           <AttachedRequirements mission={mission} />
                        </Group>
                     </Stack>

                     <Stack>
                        <Group>
                           <Group spacing='lg'>
                              <Stack>
                                 <Text size={9} weight={700} c='dimmed'>
                                    {t('b_mode')}
                                 </Text>

                                 <Group position='apart' align='center' grow spacing={'lg'}>
                                    {mission.deliveryMode === 'all' ? (
                                       <Button variant='outline'>
                                          <All />
                                       </Button>
                                    ) : (
                                       ''
                                    )}
                                    {mission.deliveryMode === 'bicycle' ? (
                                       <Button variant='outline'>
                                          <MdPedalBike />
                                       </Button>
                                    ) : (
                                       ''
                                    )}
                                    {mission.deliveryMode === 'foot' ? (
                                       <Button variant='outline'>
                                          {' '}
                                          <MdPedalBike />
                                       </Button>
                                    ) : (
                                       ''
                                    )}
                                    {mission.deliveryMode === 'car' ? (
                                       <Button variant='outline'>
                                          {' '}
                                          <AiOutlineCar />
                                       </Button>
                                    ) : (
                                       ''
                                    )}
                                 </Group>
                                 <Tooltip multiline label={'Copied to clipboard!'} transitionProps={{ duration: 300, transition: 'slide-down' }} opened={clipboard.copied}>
                                    <Button radius={5} size='xs' variant='default' uppercase onClick={() => clipboard.copy(mission.shortReference)}>
                                       {mission.shortReference}
                                    </Button>
                                 </Tooltip>
                              </Stack>
                           </Group>
                        </Group>
                     </Stack>
                  </Group>
               </Card>
            </Stack>
            <Stack>
               <Card withBorder>
                  <Group>
                     <Text weight={700}>{t('from')}.</Text>
                     <Text size='xs' color='dimmed'>
                        {mission.sender}
                     </Text>
                     <Text size='xs' c='dimmed'>
                        {mission.from.place_name}
                     </Text>
                  </Group>
               </Card>

               <Card withBorder>
                  <Group>
                     <Text weight={700}>{t('to')}.</Text>
                     <Text size='xs' color='dimmed'>
                        {mission.receiver}
                     </Text>
                     <Text size='xs' c='dimmed'>
                        {mission.createType === 'qr' ? 'Zone ' + mission.zone : ''}
                     </Text>
                     <Text size='xs' c='dimmed'>
                        {mission.createType === 'qr' && mission.hourly === true ? '1Hr Mission' : ''}
                     </Text>
                     <Text size='xs' c='dimmed'>
                        {mission.createType === 'qr' ? t('destinationqr') : mission.to.place_name}
                     </Text>
                  </Group>
               </Card>
               {mission.booker && <BookerBox mission={mission} />}

               <Card withBorder>
                  <Text size={'xs'} weight={700} c='dimmed'>
                     <b>{t('comment')}.</b>
                     {mission.comment}
                  </Text>
               </Card>
               <Card withBorder>
                  <Text size={'xs'} weight={700} c='dimmed'>
                     <b>{t('Int Ref')}.</b>
                     {mission.internalReference}
                  </Text>
               </Card>
               <Card withBorder>
                  <Stack>
                     <Text size={'xs'} weight={700} c='dimmed'>
                        {t('Rate Booker')}.
                     </Text>
                     <Rating onChange={setRating} />
                  </Stack>
               </Card>
            </Stack>
         </SimpleGrid>

         <Group position='apart' grow>
            {mission.status === 'available' && (
               <Button variant='light' onClick={cancel}>
                  Cancel Mission
               </Button>
            )}

            <ReactToPrint
               trigger={() => {
                  return <Button>{t('print')}</Button>
               }}
               content={() => componentRef.current}
            />
         </Group>

         <Stack align='center' justify='center' direction='row' className='no-print'>
            <img src={Logo} alt='logo' width='100' height='100' style={{ opacity: 0.1 }} />
         </Stack>
      </Stack>
   )
} // end component

export default Mission
