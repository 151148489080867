import Head from '../tools/head'

import React, { useState, useContext, useEffect, useRef } from 'react'
import { UserContext } from '../../_context'

import get from 'lodash/get'

import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { Stack, Button, TextInput, Modal, SimpleGrid, Grid, Textarea, Checkbox, UnstyledButton, Badge } from '@mantine/core'

import { useMediaQuery } from '@mantine/hooks'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import Map from '../tools/map'

import { useTranslation } from 'react-i18next'

import ReactToPrint from 'react-to-print'

import moment from 'moment'

import ReactGA from 'react-ga'

import { getDistanceFromLatLonInKm } from './helper/usePrice'

import { Card, Text, Group, createStyles, rem, Title, Select } from '@mantine/core'

import { MdOutlineFastfood } from 'react-icons/md'

import { RiTempHotFill } from 'react-icons/ri'

import { IoDocumentsOutline } from 'react-icons/io5'
import { GiShatteredGlass, GiThermometerCold, GiButtonFinger } from 'react-icons/gi'

import { FaShippingFast } from 'react-icons/fa'

import { QrCode2Outlined } from '@mui/icons-material'

import { GoLaw } from 'react-icons/go'

import Requirements from '../tools/requirements.js'

import EasyQrVisualInstructions from '../../assets/easyQrVisualInstructions.svg'

import EasyButtonVisualInstructions from '../../assets/easyButtonVisualInstructions.svg'

import vehicleOptions from '../../data/vehicleOptions.json'

import { GrMapLocation } from 'react-icons/gr'

const useStyles = createStyles((theme) => ({
   card: {
      backgroundColor: theme.colorScheme === 'dark' ? '#020202' : '#f1f1f1'
   },

   title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontWeight: 700
   },

   item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: theme.radius.md,
      height: rem(90),
      backgroundColor: theme.colorScheme === 'dark' ? '#020202' : '#f1f1f1',
      transition: 'box-shadow 150ms ease, transform 100ms ease',

      '&:hover': {
         boxShadow: theme.shadows.md,
         transform: 'scale(1.05)'
      }
   },

   carda: {
      minHeight: rem(150),

      border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]}`,
      '&:hover': {
         boxShadow: theme.shadows.sm,

         userSelect: 'none',

         cursor: 'pointer'
      }
   },
   chip: {
      backgroundColor: theme.colorScheme === 'dark' ? '#fff' : theme.colors.dark[7],
      color: theme.colorScheme === 'dark' ? theme.colors.dark[2] : theme.colors.gray[1],
      fontSize: rem(12)
   }
}))

// or

const EasyButtons = ({ closefunc, type }) => {
   const iconSize = 12
   const { classes } = useStyles()

   const componentRef = useRef()

   const { t } = useTranslation()

   const isMobile = useMediaQuery('(max-width: 50em)')

   const vehicleOpt = vehicleOptions.map((vehicle) => ({
      value: vehicle.value,
      label: t(vehicle.label)
   }))

   const [user] = useContext(UserContext)

   const [cities, setCities] = useState()

   const [cityList, setCityList] = useState()

   const [selectedCity, setSelectedCity] = useState()

   const [requirements, setRequirements] = useState({
      Critical: false,
      Documents: false,
      Food: false,
      Fragile: false,
      HeavyBulky: false,
      KeepCold: false,
      Urgent: false,
      Warm: false
   })

   const [zone] = useState(1)

   const [time] = useState(new Date())

   const [sender, setSender] = useState(user.name)

   const [from, setFrom] = useState(false)

   const [liveScan, setLiveScan] = useState(false)

   const [city, setCity] = useState('city')

   const [vehicle, setVehicle] = useState('all')

   const [step, setStep] = useState(0)

   const [comment, setComment] = useState('')

   const [internalReference, setinternalRererence] = useState('')

   const [openModalFrom, setOpenModalFrom] = useState(false)

   const [error, setError] = useState('.')

   const [isLoading, setIsLoading] = useState(false)

   const navigate = useNavigate()

   const [buttons, setButtons] = useState([])

   const config = {
      headers: { Authorization: `Bearer ${user.token}` }
   }

   const [buttonBody, setButtonBody] = useState('')

   const apiServer = process.env.REACT_APP_API_URL

   useEffect(() => {
      axios.get(apiServer + '/cities').then((res) => {
         const cityList = res.data

         setCityList(cityList)

         const cit = res.data.map((city) => ({ value: city.name, label: city.name }))

         setCities(cit)
      })
   }, [])

   useEffect(() => {
      axios.get(apiServer + (type === 'QR' ? '/poster/qrbuttonsget/' : '/poster/easyButtonsget/'), config).then((res) => {
         if (res.data.qrButtons.length > 0) {
            setButtons(res.data.qrButtons)
            setStep(3)
            setButtonBody(res.data.qrButtons[0].body)
         } else {
            setStep(1)
         }
      })
   }, [type])

   ReactGA.initialize(process.env.REACT_APP_GA_ID, {
      gaOptions: {
         name: 'posters',
         userId: user.name
      }
   })

   const formSubmit = async (event) => {
      setError('.')

      if (!from) {
         setError(t('mistext2'))
         setTimeout(() => {
            setError('.')
         }, 3000)
         return
      }

      if (!selectedCity) {
         setError(t('mistext3'))
         setTimeout(() => {
            setError('.')
         }, 3000)
         return
      }

      var fromDistanceFromCity = await getDistanceFromLatLonInKm(selectedCity?.latLang[1], selectedCity?.latLang[0], from.center[1], from.center[0])

      if (fromDistanceFromCity > Number(selectedCity?.zones.MaxRange.distance)) {
         setError(t('mistext4'))

         setTimeout(() => {
            setError('.')
         }, 3000)
         return false
      }

      if (!city > Number(selectedCity.zones.MaxRange.distance)) {
         setError(t('mistext5'))
         setTimeout(() => {
            setError('.')
         }, 3000)
         return false
      }

      setStep(2)
   }

   // TODO: MISSION CREATE

   const qrCreate = async (event) => {
      console.log(liveScan)

      const body = {
         time: moment(time).format('YYYY-MM-DD HH:mm:ss'),
         city: city,
         tz: selectedCity.tz ? selectedCity.tz : 'Europe/Zurich',
         from: from,
         sender: user.name,
         zone: zone,
         vehicle: vehicle,

         requirements: requirements,
         deliveryMode: vehicle,
         comment: comment,
         internalReference: internalReference,
         type: type === 'QR' ? 'QR' : 'BN',
         liveScan: liveScan
      }

      setIsLoading(true)

      await axios
         .post(apiServer + '/poster/qrcreate/', body, config)
         .then((response) => {
            setIsLoading(false)

            setStep(4)
            navigate()
         })
         .catch((error) => {
            var mess = error.response?.data
            console.log(error)
            setError(mess)
            setTimeout(() => {
               setError('.')
            }, 3000)
            return false
         })
         .finally(() => {
            setIsLoading(false)
         })
   }

   const fromFrom = (e) => {
      setFrom(e.result)
   }

   const senderHandler = (e) => {
      setSender(e.target.value)
   }

   const modalFrom = <Modal fullScreen={isMobile} size={'xl'} title={t('from')} opened={openModalFrom} onClose={() => setOpenModalFrom(false)}></Modal>

   const form = (
      <>
         {modalFrom}

         <Card withBorder>
            <Stack>
               <Grid grow>
                  <Grid.Col span={4}>
                     <Card createstyles={{ minHeight: '170px' }} withBorder className={classes.carda}>
                        <Stack>
                           <Group>
                              <Title order={6}>{t('defaultSenderName')}</Title>
                           </Group>

                           <Stack spacing={20}>
                              <TextInput id='name' defaultValue={user.name} onChange={senderHandler} />
                              <Group grow position='apart'>
                                 {cities && (
                                    <>
                                       <Stack>
                                          <Title order={6}>{t('defaultPickupCity')}</Title>
                                          <Select
                                             size='lg'
                                             value={city}
                                             data={cities}
                                             placeholder={city}
                                             onChange={(ev) => {
                                                setCity(ev)
                                                let result = cityList.filter((e) => e.name === ev)

                                                setSelectedCity(result[0])
                                             }}></Select>
                                       </Stack>
                                    </>
                                 )}
                              </Group>

                              <Stack>
                                 <Title order={6}>{t('DefaultPickupAddress')}</Title>
                                 <Text size={'xs'}>{get(from, 'place_name', '...')}</Text>

                                 <Map
                                    key='1'
                                    func={fromFrom}
                                    centr={{
                                       latitude: selectedCity ? selectedCity?.latLang[1] : 46.2044,
                                       longitude: selectedCity ? selectedCity?.latLang[0] : 6.1432,
                                       zoom: 12
                                    }}
                                 />
                              </Stack>
                           </Stack>

                           <Stack spacing={20} sx={{ paddingTop: '70px' }} direction={'row'}>
                              <Checkbox label='Or Select Live Scan Location' checked={liveScan} onChange={(e) => setLiveScan(e.target.checked)}></Checkbox>
                           </Stack>
                        </Stack>
                     </Card>
                  </Grid.Col>
               </Grid>

               <Stack spacing={10}>
                  <Title order={6}>{t('defaultRequirements')}</Title>
                  <Text size='sm'>{t('markuptext1')}</Text>
                  <Text size='sm' c='dimmed'>
                     {t('markuptext2')}
                  </Text>
                  <Requirements requirements={requirements} setRequirements={setRequirements} />
               </Stack>
               <Stack gap='20px'>
                  <Title order={6}>{t('defaultComments')}</Title>
                  <Textarea id='outlined-textarea' size='md' label={'Default ' + t('comment')} placeholder=' ' multiline rows={4} value={comment} onChange={(e) => setComment(e.target.value)} />

                  <Title order={6}>{t('defaultintRef')}</Title>
                  <TextInput id='outlined-textarea' size='md' label={'Default ' + t('internalref')} placeholder=' ' value={internalReference} onChange={(e) => setinternalRererence(e.target.value)} />
               </Stack>

               <Title order={6}>Default {t('b_vehicle')}</Title>
               <Select size='xl' value={vehicle} data={vehicleOpt} onChange={setVehicle} />
               <div className='invalid'>{error}</div>
            </Stack>
         </Card>

         <Button size='xl' fullWidth className='mission-button' onClick={formSubmit}>
            {t('Order')}
         </Button>
      </>
   )

   const check = (
      <>
         <Card>
            <Card withBorder>
               <Stack spacing={10}>
                  <Group>
                     <Title order={6}>{t('defaultSenderName')}</Title>
                     <Text>{sender}</Text>
                  </Group>

                  <Group>
                     <Title order={6}>{t('defaultPickupCity')}</Title>
                     <Text>{city}</Text>
                  </Group>

                  <Group>
                     <Title order={6}>{t('DefaultPickupAddress')}</Title>
                     <Text> {get(from, 'place_name', '...')}</Text>
                  </Group>

                  <Group>
                     <Title order={6}>{t('defaultRequirements')}</Title>
                     <Group spacing={5}>
                        {requirements?.Food && <MdOutlineFastfood size={iconSize} />}
                        {requirements?.KeepCold && <GiThermometerCold size={iconSize} />}
                        {requirements?.Warm && <RiTempHotFill size={iconSize} />}
                        {requirements?.Documents && <IoDocumentsOutline size={iconSize} />}
                        {requirements?.Fragile && <GiShatteredGlass size={iconSize} />}
                        {requirements?.Urgent && <FaShippingFast size={iconSize} />}
                        {requirements?.Critical && <GoLaw size={iconSize} />}
                     </Group>
                  </Group>

                  <Group>
                     <Title order={6}>{t('defaultComments')}</Title>
                     <Text>{comment}</Text>
                  </Group>

                  <Group>
                     <Title order={6}>{t('defaultintRef')}</Title>
                     <Text>{internalReference}</Text>
                  </Group>

                  <Group>
                     <Title order={6}>Default {t('b_vehicle')}</Title>
                     <Text>{vehicle}</Text>
                  </Group>
               </Stack>
            </Card>

            <p>
               <small style={{ color: 'GrayText' }}>The Ped’Allo Button is not a toy - Clicking the Button will irrevocable trigger a delivery mission option, which might be charged to you – Please use it responsibly  Posted Missions can be manually cancelled by logging into platform.pedallo.com, and going to Your Missions, where you can manually cancel each mission PROVIDING IT HAS NOT BEEN BOOKED YET</small>
            </p>
            <div className='invalid' style={{ alignContent: 'center', justifyContent: 'center', height: '100xp' }}>
               {error}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
               <Button onClick={() => setStep(1)} variant='outline' color='secondary'>
                  {'<'} {t('back')}
               </Button>

               <Button loading={isLoading} onClick={qrCreate}>
                  {t('Confirm Order Qr Easy Buttons')}
               </Button>
            </div>
         </Card>
      </>
   )

   const loading = <></>

   const success = (
      <Card>
         <div ref={componentRef}>
            <Stack>
               <h1>{t('thanks')}</h1>
               <Text>{t('EBthanks')}</Text>
            </Stack>
         </div>
         <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
            <Button onClick={() => navigate('/logged/poster')} variant='outline' color='secondary'>
               {'<'} {t('back')}
            </Button>
         </div>
      </Card>
   )

   const accept = (
      <Card>
         <div ref={componentRef}>
            <Stack>
               <h1>{t('thanks')}</h1>
               <Text>{t('EBthanks1')}</Text>
               <Title order={6}>{t('buttons')}</Title>

               <SimpleGrid
                  cols={3}
                  breakpoints={[
                     { maxWidth: '62rem', cols: 3, spacing: 'md' },
                     { maxWidth: '48rem', cols: 2, spacing: 'sm' },
                     { maxWidth: '36rem', cols: 1, spacing: 'sm' }
                  ]}>
                  {buttons.map((button, key) => (
                     <UnstyledButton
                        key={key}
                        onClick={(e) => {
                           setButtonBody(button.body)
                        }}>
                        <Card withBorder className='buttons'>
                           <Group position='apart'>
                              {type === 'QR' ? <QrCode2Outlined /> : <GiButtonFinger />}
                              {button.body.liveScan ? <GrMapLocation /> : <></>}
                           </Group>
                           {button.approved ? <Badge color='green'>{t('approved')}</Badge> : <Badge color='yellow'>{t('pending')}</Badge>}

                           <Text weight={700}>{button.body?.city}</Text>

                           <Text size='xs'> {get(button.body?.from, 'place_name', '...')}</Text>
                        </Card>
                     </UnstyledButton>
                  ))}
               </SimpleGrid>
               <Button
                  onClick={() => {
                     setStep(1)
                  }}>
                  Order Another Button!
               </Button>

               <Card withBorder>
                  <Stack spacing={10}>
                     <Group>
                        <Title order={6}>{t('defaultSenderName')}</Title>
                        <Text>{buttonBody.sender}</Text>
                     </Group>

                     <Group>
                        <Title order={6}>{t('defaultPickupCity')}</Title>
                        <Text>{buttonBody.city}</Text>
                     </Group>

                     {buttonBody.liveScan ? (
                        <Badge color='green'>Live Location on Scan Active</Badge>
                     ) : (
                        <Group>
                           <Title order={6}>{t('DefaultPickupAddress')}</Title>
                           <Text> {get(buttonBody.from, 'place_name', '...')}</Text>
                        </Group>
                     )}

                     <Group>
                        <Title order={6}>{t('defaultRequirements')}</Title>
                        <Group spacing={5}>
                           {buttonBody.requirements?.Food && <MdOutlineFastfood size={iconSize} />}
                           {buttonBody.requirements?.KeepCold && <GiThermometerCold size={iconSize} />}
                           {buttonBody.requirements?.Warm && <RiTempHotFill size={iconSize} />}
                           {buttonBody.requirements?.Documents && <IoDocumentsOutline size={iconSize} />}
                           {buttonBody.requirements?.Fragile && <GiShatteredGlass size={iconSize} />}
                           {buttonBody.requirements?.Urgent && <FaShippingFast size={iconSize} />}
                           {buttonBody.requirements?.Critical && <GoLaw size={iconSize} />}
                        </Group>
                     </Group>

                     <Group>
                        <Title order={6}>{t('defaultComments')}</Title>
                        <Text>{buttonBody.comment}</Text>
                     </Group>

                     <Group>
                        <Title order={6}>{t('defaultintRef')}</Title>
                        <Text>{buttonBody.internalReference}</Text>
                     </Group>

                     <Group>
                        <Title order={6}>Default {t('b_vehicle')}</Title>
                        <Text>{buttonBody.vehicle}</Text>
                     </Group>
                  </Stack>
               </Card>
               <Text>{t('EBthanks2')}</Text>
            </Stack>
         </div>

         <Stack style={{ padding: '50px' }}>
            <Group position='apart'>
               <Button loading={isLoading} onClick={() => navigate(-1)} variant='light'>
                  {t('backHome')}
               </Button>

               <ReactToPrint
                  trigger={() => {
                     return (
                        <Button variant='outline' href='#'>
                           {t('print')}
                        </Button>
                     )
                  }}
                  content={() => componentRef.current}
               />
            </Group>
         </Stack>
      </Card>
   )

   const renderSwitch = () => {
      switch (step) {
         case 1:
            return form

         case 2:
            return check

         case 3:
            return accept

         case 4:
            return success

         default:
            return loading
      }
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Head type='main' />

         <Card>
            <Stack>
               <Group position='apart'>
                  <Title order={6}>{t('Order Pedello Easy Buttons with Default Settings')}</Title>
               </Group>
               <img src={type === 'BN' ? EasyButtonVisualInstructions : EasyQrVisualInstructions} alt='React Logo' />
               {renderSwitch()}
            </Stack>
         </Card>
      </LocalizationProvider>
   )
}

export default EasyButtons

// refactor this code to files
