import React, { useContext, useState } from 'react'
import moment from 'moment'

import { HiOutlineEmojiSad } from 'react-icons/hi'

import PosterBox from './helpers/posterBox'

import { MdPedalBike } from 'react-icons/md'
import { AiOutlineCar } from 'react-icons/ai'

import { useTranslation } from 'react-i18next'
import { UserContext } from '../../_context'

import axios from 'axios'

import ReactGA from 'react-ga'
import StepperMissions from '../tools/StepperMissions'

import { Button, Card, Stack, Text, Group, SimpleGrid, Tooltip, Badge } from '@mantine/core'

import { useClipboard } from '@mantine/hooks'

import AttachedRequirements from '../tools/AttachedRequirements'

import Map from '../tools/mapc'

function Mission({ mission, closeFunc, setShow, setBookedShow, bookedShow }) {
   const missionsNo = 1

   const [err, setErr] = useState({})
   const clipboard = useClipboard()

   /// Icon Size

   const apiServer = process.env.REACT_APP_API_URL

   const [user] = useContext(UserContext)

   const config = { headers: { Authorization: `Bearer ${user.token}` } }

   const { t } = useTranslation()
   const All = () => {
      return 'ALL'
   }

   const [activeStep, setActiveStep] = React.useState(mission.status === 'booked' ? 2 : 0)

   const book = (e) => {
      axios
         .post(apiServer + '/booker/mission/book', { id: mission._id }, config)
         .then((res) => {
            ReactGA.event({
               category: 'Book',
               action: 'Mission Booking ' + user.name,
               label: res.data
            })
            return setActiveStep(2)
         })
         .catch((err) => {
            return setActiveStep(3)
         })
   }
   if (activeStep === 0) {
      return (
         <>
            <Stack>
               <Group position='apart' align='top' spacing={20}>
                  <Stack>
                     <Text weight={700} size='sm'>
                        {t('pickup_time')} {moment(mission.time).calendar()}
                     </Text>
                     <Group>
                        {mission.immediate && <div className={'immediate'}> {mission.immediate ? 'immediate' : ''}</div>}
                        {mission.hourly && (
                           <Badge size='xs' color='yellow'>
                              {mission.hourly ? t('hourly') + ' +' + mission.hourlyRate + ' hrs.' : ''}
                           </Badge>
                        )}
                     </Group>
                     <Group position='apart' grow>
                        <AttachedRequirements mission={mission} />
                     </Group>
                  </Stack>
                  <Stack spacing={'xs'}>
                     <Text size='xl' weight={900}>
                        Net
                     </Text>
                     <Text size='xl' weight={900}>
                        {mission.pricing.price.bookerNet} {mission.pricing.currency.name}
                     </Text>
                  </Stack>
                  <Stack spacing={10}>
                     <Group>
                        <Group spacing='lg'>
                           <Stack>
                              <Tooltip width={80} multiline label={'Copied to clipboard!'} transitionProps={{ duration: 300, transition: 'slide-down' }} opened={clipboard.copied}>
                                 <Button radius={5} size='xs' variant='default' uppercase onClick={() => clipboard.copy(mission.shortReference)}>
                                    {mission.shortReference}
                                 </Button>
                              </Tooltip>
                              <Text size={'xs'} weight={700} c='dimmed'>
                                 {t('b_mode')}
                              </Text>

                              <Group position='apart' align='center' grow spacing={'lg'}>
                                 {mission.deliveryMode === 'all' ? (
                                    <Button variant='outline'>
                                       <All />
                                    </Button>
                                 ) : (
                                    ''
                                 )}
                                 {mission.deliveryMode === 'bicycle' ? (
                                    <Button variant='outline'>
                                       <MdPedalBike />
                                    </Button>
                                 ) : (
                                    ''
                                 )}
                                 {mission.deliveryMode === 'foot' ? (
                                    <Button variant='outline'>
                                       {' '}
                                       <MdPedalBike />
                                    </Button>
                                 ) : (
                                    ''
                                 )}
                                 {mission.deliveryMode === 'car' ? (
                                    <Button variant='outline'>
                                       {' '}
                                       <AiOutlineCar />
                                    </Button>
                                 ) : (
                                    ''
                                 )}
                              </Group>
                           </Stack>
                        </Group>
                     </Group>
                  </Stack>
               </Group>

               <SimpleGrid cols={2} spacing={20} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                  <Stack>
                     {missionsNo < 3 ? (
                        <Button onClick={() => setActiveStep(1)}>{t('book')}</Button>
                     ) : (
                        <Stack spacing={2}>
                           <div>
                              <div style={{ marginTop: '10px', color: 'firebrick' }}>
                                 <b>You can only book 3 Missions at a time.</b>
                              </div>
                              <div style={{ marginTop: '10px', fontSize: '10px' }}>
                                 <b>Please fullfil one of your Missions to book another.</b>
                              </div>
                           </div>
                        </Stack>
                     )}

                     <Map mission={mission} width='100' height='100' />
                  </Stack>
                  <Stack>
                     <Card withBorder>
                        <Group>
                           <Text weight={700}>{t('from')}.</Text>
                           <Text size='xs' c='dimmed'>
                              {mission.from.place_name}
                           </Text>
                        </Group>
                     </Card>

                     <Card withBorder>
                        <Group>
                           <Text weight={700}>{t('to')}.</Text>
                           <Text size='xs' c='dimmed'>
                              {mission.createType === 'qr' ? 'Zone ' + mission.zone : ''}
                              {mission.hourly && (
                                 <Badge size='xs' color='yellow'>
                                    {mission.hourly ? t('hourly') + ' +' + mission.hourlyRate + ' hrs.' : ''}
                                 </Badge>
                              )}
                           </Text>
                           <Text size='xs' c='dimmed'>
                              <Text size='xs' c='dimmed'>
                                 {mission.createType === 'qr' ? t('destinationqr') : mission.to.place_name}
                              </Text>
                           </Text>
                        </Group>
                     </Card>
                     <Card withBorder>
                        <Text size={'xs'} weight={700} c='dimmed'>
                           <b>{t('comment')}.</b>
                           {mission.comment}
                        </Text>
                     </Card>
                  </Stack>
               </SimpleGrid>
            </Stack>
         </>
      )
   }

   if (activeStep === 1) {
      return (
         <div>
            <div style={{ textAlign: 'center' }}>
               <div style={{ padding: '50px' }}>
                  <div>
                     <b>{t('bookinf1')}</b> <span style={{ color: 'GrayText' }}>{t('bookinf2')}</span>
                  </div>
               </div>
               <Stack gap='10px' direction='row'>
                  <Button fullWidth variant='light' onClick={closeFunc}>
                     {t('cancel')}
                  </Button>
                  <Button fullWidth onClick={(e) => book(e)}>
                     {t('book')}
                  </Button>
               </Stack>
            </div>
         </div>
      )
   }
   if (activeStep === 2) {
      /// DONE
      return (
         <div style={{ textAlign: 'center' }}>
            <h1>{t('congrats')}</h1>
            {t('bookinf3')} <b># {mission.shortReference} </b>. {t('bookinf4')}
            <h3>{t('bookinf5')} </h3>
            <Button fullWidth onClick={closeFunc}>
               {t('close')}
            </Button>
         </div>
      )
   }
   if (activeStep === 3) {
      // SORRY
      return (
         <div style={{ textAlign: 'center' }}>
            <div style={{ margin: '30px' }}>
               <HiOutlineEmojiSad style={{ fontSize: '80px', color: 'firebrick' }} />
               <h1>Sorry! </h1>
               <div style={{ color: 'GrayText' }}>{err}</div>
            </div>

            <Button fullWidth variant='outlined' onClick={setShow}>
               {t('close')}
            </Button>
         </div>
      )
   }
   return (
      <Stack>
         <StepperMissions mission={mission} />

         <Card withBorder>
            <Group position='apart' align='top'>
               <Stack spacing={0}>
                  <Text weight={700} size='sm'>
                     {t('pickup_time')} {moment(mission.time).calendar()}
                  </Text>
                  {mission.immediate && <div className={'immediate'}> {mission.immediate ? 'immediate' : ''}</div>}
                  {mission.hourly && (
                     <Badge size='xs' color='yellow'>
                        {mission.hourly ? t('hourly') + ' +' + mission.hourlyRate + ' hrs.' : ''}
                     </Badge>
                  )}
                  <Group position='apart' grow>
                     <AttachedRequirements mission={mission} />
                  </Group>
               </Stack>

               <Stack>
                  <Group>
                     <Group spacing='lg'>
                        <Stack>
                           <Tooltip width={80} multiline label={'Copied to clipboard!'} transitionProps={{ duration: 300, transition: 'slide-down' }} opened={clipboard.copied}>
                              <Button radius={5} size='xs' variant='default' uppercase onClick={() => clipboard.copy(mission.shortReference)}>
                                 {mission.shortReference}
                              </Button>
                           </Tooltip>
                           <Text size={'xs'} weight={700} c='dimmed'>
                              {t('b_mode')}
                           </Text>

                           <Group position='apart' align='center' grow spacing={'lg'}>
                              {mission.deliveryMode === 'all' ? (
                                 <Button variant='outline'>
                                    <All />
                                 </Button>
                              ) : (
                                 ''
                              )}
                              {mission.deliveryMode === 'bicycle' ? (
                                 <Button variant='outline'>
                                    <MdPedalBike />
                                 </Button>
                              ) : (
                                 ''
                              )}
                              {mission.deliveryMode === 'foot' ? (
                                 <Button variant='outline'>
                                    {' '}
                                    <MdPedalBike />
                                 </Button>
                              ) : (
                                 ''
                              )}
                              {mission.deliveryMode === 'car' ? (
                                 <Button variant='outline'>
                                    {' '}
                                    <AiOutlineCar />
                                 </Button>
                              ) : (
                                 ''
                              )}
                           </Group>
                        </Stack>
                     </Group>
                  </Group>
               </Stack>
            </Group>
         </Card>

         <Stack>
            <Card withBorder>
               <Group>
                  <Text weight={700}>{t('from')}.</Text>
                  <Text size='xs' c='dimmed'>
                     {mission.from.place_name}
                  </Text>
               </Group>
            </Card>

            <Card withBorder>
               <Group>
                  <Text weight={700}>{t('to')}.</Text>
                  <Text size='xs' c='dimmed'>
                     {mission.to.place_name}
                  </Text>
               </Group>
            </Card>
            {mission.booker && <PosterBox mission={mission} />}
         </Stack>
         <Stack>
            <Map mission={mission} width='100' height='100' />

            <Card withBorder>
               <Text size={'xs'} weight={700} c='dimmed'>
                  <b>{t('comment')}.</b>
                  {mission.comment}
               </Text>
               {mission.hourly && (
                  <Badge size='xs' color='yellow'>
                     {mission.hourly ? t('hourly') + ' +' + mission.hourlyRate + ' hrs.' : ''}
                  </Badge>
               )}
            </Card>
         </Stack>

         <Group position='apart' grow>
            <Button onClick={book}>{t('book')}</Button>
         </Group>
      </Stack>
   )
} // end component

export default Mission
