import Head from '../tools/head'

import React, { useState, useContext, useEffect, useRef } from 'react'
import { UserContext } from '../../_context'
import { modals } from '@mantine/modals'

import get from 'lodash/get'
import find from 'lodash/find'

import axios from 'axios'
import { useNavigate } from 'react-router-dom'

import { FocusTrap, Stack, Divider, Button, TextInput, Modal, Grid, Textarea } from '@mantine/core'

import { useMediaQuery } from '@mantine/hooks'

import Chip from '@mui/material/Chip'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { BsFillPinMapFill, BsPinMap } from 'react-icons/bs'

import { AiOutlineCloudDownload } from 'react-icons/ai'

import Map from '../tools/map'

import { useTranslation } from 'react-i18next'

import ReactToPrint from 'react-to-print'

import moment from 'moment'

import ReactGA from 'react-ga'
import { AiFillCar } from 'react-icons/ai'

import { BsBicycle } from 'react-icons/bs'

import { MdOutlineElectricScooter } from 'react-icons/md'

import { getDistanceFromLatLonInKm, calcDistance, calcHourly, getDrivingDirectionMarkers } from './helper/usePrice'

import { Card, Text, Anchor, Checkbox, Group, createStyles, rem, Title, Switch, Select } from '@mantine/core'

import { BsCreditCard2Front } from 'react-icons/bs'

import Requirements from '../tools/requirements.js'
import { DateTimePicker } from '@mantine/dates'

const useStyles = createStyles((theme) => ({
   card: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0]
   },

   title: {
      fontFamily: `Greycliff CF, ${theme.fontFamily}`,
      fontWeight: 700
   },

   item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      borderRadius: theme.radius.md,
      height: rem(90),
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.white,
      transition: 'box-shadow 150ms ease, transform 100ms ease',

      '&:hover': {
         boxShadow: theme.shadows.md,
         transform: 'scale(1.05)'
      }
   },

   carda: {
      minHeight: rem(150),
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.white,
      border: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]}`,
      '&:hover': {
         boxShadow: theme.shadows.sm,
         transform: 'scale(1.02)',
         userSelect: 'none',

         cursor: 'pointer'
      }
   }
}))

// or

const Missions = ({ closefunc }) => {
   const { classes, theme } = useStyles()

   const setTemplate = (e) => {
      const selected = templates[e]

      setHourly(selected?.hourly)
      setHourlyRate(selected?.hourlyRate)

      setCity(selected?.city)

      setFrom(selected?.from)
      setTo(selected?.to)

      var returnCity = find(cityDetails, function (o) {
         return o.name === selected.city
      })

      setSelectedCity(returnCity)

      setSender(selected.sender)
      setReceiver(selected.receiver)

      setComment(selected.comment)
      setinternalRererence(selected.internalReference)
   }

   const componentRef = useRef()

   const { t } = useTranslation()

   const isMobile = useMediaQuery('(max-width: 50em)')

   const data = [
      { value: 'all', label: t('posttoall'), icon: <AiFillCar /> },
      { value: 'car', label: t('b_veh_opt_1'), icon: <AiFillCar /> },
      { value: 'motorcycle', label: t('b_veh_opt_3'), icon: <MdOutlineElectricScooter /> },
      { value: 'bicycle', label: t('b_veh_opt_2'), icon: <BsBicycle /> },
      { value: 'foot', label: t('b_veh_opt_4'), icon: <BsBicycle /> }
   ]

   const [user] = useContext(UserContext)

   const [cities, setCities] = useState()

   const [cityList, setCityList] = useState()

   const [cityDetails, setcityDetails] = useState()
   const [selectedCity, setSelectedCity] = useState()

   const [requirements, setRequirements] = useState({
      Critical: false,
      Documents: false,
      Food: false,
      Fragile: false,
      HeavyBulky: false,
      KeepCold: false,
      Urgent: false,
      Warm: false
   })

   const [immediate, setImmediate] = useState(true)

   const [hourly, setHourly] = useState(false)

   const [hourlyRate, setHourlyRate] = useState(1)

   const [time, setTime] = useState(new Date())

   const [to, setTo] = useState(false)

   const [sender, setSender] = useState(user.name)

   const [receiver, setReceiver] = useState('')

   const [from, setFrom] = useState(false)

   const [calcPrice, setCalcPrice] = useState(false)

   const [city, setCity] = useState('city')

   const [vehicle, setVehicle] = useState('all')

   const [step, setStep] = useState(1)

   const [weight, setWeight] = useState(0)

   const [comment, setComment] = useState('')

   const [distance, setDistance] = useState(0)

   const [internalReference, setinternalRererence] = useState('')

   const [suggestedPrice, setSuggestedPrice] = useState('')

   const [openModalFrom, setOpenModalFrom] = useState(false)
   const [openModalTo, setOpenModalTo] = useState(false)

   const [disabled, setDisabled] = useState(true)

   const [error, setError] = useState('.')

   const [isLoading, setIsLoading] = useState(false)

   const [shortReference, setShortReference] = useState('')

   const navigate = useNavigate()

   const [templates, setTemplates] = useState(JSON.parse(localStorage.getItem('templates_' + user._id)))

   const config = {
      headers: { Authorization: `Bearer ${user.token}` }
   }
   const [tempName] = useState('')

   useEffect(() => {
      const apiServer = process.env.REACT_APP_API_URL

      axios.get(apiServer + '/cities').then((res) => {
         const cityList = res.data

         setCityList(cityList)

         const cit = res.data.map((city) => ({ value: city.name, label: city.name }))

         console.log(cityList)

         setCities(cit)

         setcityDetails(res.data)
      })
   }, [])

   const apiServer = process.env.REACT_APP_API_URL

   ReactGA.initialize(process.env.REACT_APP_GA_ID, {
      gaOptions: {
         name: 'posters',
         userId: user.name
      }
   })

   const useAnalyticsEventTracker = (category = 'Post Mission', mission = 'mission') => {
      const eventTracker = (action = user.name, label = 'test label') => {
         ReactGA.event({ category, action, label })
      }
      return eventTracker
   }

   const inputRef = useRef()

   const gaEventTracker = useAnalyticsEventTracker()

   const missionSaveForLater = async (saveName) => {
      setError('.')

      if (!from) {
         setError(t('mistext2'))
         navigator.vibrate && navigator.vibrate([30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30])
         setTimeout(() => {
            setError('.')
         }, 3000)
         return
      }

      if (!hourly && !to) {
         setError(t('mistext1'))
         navigator.vibrate && navigator.vibrate([30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30])
         setTimeout(() => {
            setError('.')
         }, 3000)
         return
      }

      if (city === '') {
         setError(t('mistext3'))
         navigator.vibrate && navigator.vibrate([30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30])
         setTimeout(() => {
            setError('.')
         }, 3000)
         return
      }

      var fromDistanceFromCity = await getDistanceFromLatLonInKm(selectedCity.latLang[1], selectedCity.latLang[0], from.center[1], from.center[0])

      var toDistanceFromCity = hourly ? 0 : await getDistanceFromLatLonInKm(selectedCity.latLang[1], selectedCity.latLang[0], to.center[1], to.center[0])

      var distanceBetween = hourly ? 0 : await getDistanceFromLatLonInKm(from.center[1], from.center[0], to.center[1], to.center[0])

      if (fromDistanceFromCity > Number(selectedCity.zones.MaxRange.distance)) {
         setError(t('mistext4')) // err from distance is out of range

         setTimeout(() => {
            setError('.')
         }, 3000)
         return false
      }

      if (!hourly && toDistanceFromCity > Number(selectedCity.zones.MaxRange.distance)) {
         setError(t('mistext4')) // err to distance is out of range
         setTimeout(() => {
            setError('.')
         }, 3000)
         return false
      }

      if (!hourly && distanceBetween > Number(selectedCity.zones.MaxRange.distance)) {
         setError(t('mistext5')) // err distance between is out of range
         setTimeout(() => {
            setError('.')
         }, 3000)
         return false
      }

      let body = {
         immediate: immediate,
         hourly: hourly,
         hourlyRate: hourlyRate,
         time: time,
         city: city,
         from: from,
         to: to,

         sender: user.name,
         receiver: receiver,

         comment: comment,
         internalReference: internalReference
      }

      var beforeitems = JSON.parse(localStorage.getItem('templates_' + user._id)) || {} // get the current items in local storage

      var fin = { ...beforeitems, [saveName]: body }

      var item = JSON.stringify(fin)

      localStorage.setItem('templates_' + user._id, item)

      setTemplates(fin)
   }

   const handleChangeRequirements = (event) => {
      let req = event.target.id

      if (req === 'HeavyBulky') {
         if (requirements.HeavyBulky) {
            setDisabled(true)
         } else {
            setDisabled(false)
         }
      }

      setRequirements({ ...requirements, [req]: event.target.checked })
   }

   const getCityFromGeojson = (geoData) => {
      var ret = get(
         find(geoData.context, function (o) {
            return o.id.indexOf('place') >= 0
         }),
         'text',
         '?'
      )

      return ret
   }

   const formSubmit = async (event) => {
      setError('.')

      if (!from) {
         setError(t('mistext2'))
         navigator.vibrate && navigator.vibrate([30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30])
         setTimeout(() => {
            setError('.')
         }, 3000)
         return
      }

      if (!hourly && !to) {
         setError(t('mistext1'))
         navigator.vibrate && navigator.vibrate([30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30])
         setTimeout(() => {
            setError('.')
         }, 3000)
         return
      }

      if (!selectedCity) {
         setError(t('mistext3'))
         navigator.vibrate && navigator.vibrate([30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30])
         setTimeout(() => {
            setError('.')
         }, 3000)
         return
      }

      var fromDistanceFromCity = await getDistanceFromLatLonInKm(selectedCity?.latLang[1], selectedCity?.latLang[0], from.center[1], from.center[0])

      var toDistanceFromCity = hourly ? 0 : await getDistanceFromLatLonInKm(selectedCity?.latLang[1], selectedCity?.latLang[0], to.center[1], to.center[0])

      var distanceBetween = hourly ? 0 : await getDistanceFromLatLonInKm(from.center[1], from.center[0], to.center[1], to.center[0])

      if (fromDistanceFromCity > Number(selectedCity?.zones.MaxRange.distance)) {
         setError(t('mistext4'))
         navigator.vibrate && navigator.vibrate([30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30])
         setTimeout(() => {
            setError('.')
         }, 3000)
         return false
      }

      if (!hourly && toDistanceFromCity > Number(selectedCity?.zones.MaxRange.distance)) {
         setError(t('mistext4'))
         navigator.vibrate && navigator.vibrate([30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30])
         setTimeout(() => {
            setError('.')
         }, 3000)
         return false
      }

      if (!hourly && distanceBetween > Number(selectedCity.zones.MaxRange.distance)) {
         setError(t('mistext5'))
         navigator.vibrate && navigator.vibrate([30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30])
         setTimeout(() => {
            setError('.')
         }, 3000)
         return false
      }
      if (!city > Number(selectedCity.zones.MaxRange.distance)) {
         setError(t('mistext5'))
         navigator.vibrate && navigator.vibrate([30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30])
         setTimeout(() => {
            setError('.')
         }, 3000)
         return false
      }

      setCalcPrice(hourly ? calcHourly(requirements, selectedCity, hourly, hourlyRate, vehicle) : calcDistance(requirements, selectedCity, distanceBetween, vehicle))

      console.log('calcPrice', distanceBetween)
      setDistance(distanceBetween)

      getDrivingDirectionMarkers(from.center[1], from.center[0], to.center[1], to.center[0])

      setStep(2)
   }

   // TODO: MISSION CREATE

   const missionCreate = async (event) => {
      var tz = selectedCity.tz ? selectedCity.tz : 'Europe/Zurich'

      const body = {
         immediate: immediate,
         hourly: hourly,
         hourlyRate: hourlyRate,
         time: moment(time).format('YYYY-MM-DD HH:mm:ss'),
         city: city,
         tz: tz,
         from: from,
         to: to,
         sender: user.name,
         receiver: receiver,

         requirements: requirements,
         deliveryMode: vehicle,
         suggestedPrice: suggestedPrice,
         pricing: { price: calcPrice, currency: selectedCity.currency },
         comment: comment,
         internalReference: internalReference,
         weight: weight
      }

      setIsLoading(true)

      await axios
         .post(apiServer + '/poster/mission/', body, config)
         .then((response) => {
            ReactGA.event({
               category: 'Mission Post',
               action: 'Posted Mission' + user.name,
               label: response.data.mission.shortReference
            })

            setIsLoading(false)
            setShortReference(response.data.mission.shortReference)

            gaEventTracker('call')
            setStep(3)
         })
         .catch((error) => {
            ReactGA.event({
               category: 'Mission Post Error',
               action: 'Posted Mission' + user.name,
               label: error.response.data
            })

            var mess = error.response.data

            setError(mess)
            navigator.vibrate && navigator.vibrate([30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30, 30])
            setTimeout(() => {
               setError('.')
            }, 3000)
            return false
         })
         .finally(() => {
            setIsLoading(false)
         })
   }

   const missionClose = (event) => {
      navigate(-1)
   }

   const toto = (e) => {
      setTo(e.result)
      cities.to = getCityFromGeojson(e.result)
   }

   const fromFrom = (e) => {
      setFrom(e.result)
      cities.from = getCityFromGeojson(e.result)
   }

   const senderHandler = (e) => {
      setSender(e.target.value)
   }

   const modalFrom = (
      <Modal fullScreen={isMobile} size={'xl'} title={t('from')} opened={openModalFrom} onClose={() => setOpenModalFrom(false)}>
         <FocusTrap active>
            <Stack>
               <TextInput label={t('sendername')} defaultValue={user.name} onChange={senderHandler} data-autofocus />

               <Map
                  key='1'
                  func={fromFrom}
                  country={selectedCity?.location?.context[1]?.short_code || selectedCity?.location?.context[0]?.short_code || 'ch'}
                  centr={{
                     latitude: selectedCity ? selectedCity?.latLang[1] : 46.2044,
                     longitude: selectedCity ? selectedCity?.latLang[0] : 6.1432,
                     zoom: 12
                  }}
               />
            </Stack>
         </FocusTrap>
         <Stack spacing={1} sx={{ paddingTop: '70px' }} direction={'row'}>
            <Button
               onClick={(e) => {
                  setOpenModalFrom(false)
               }}>
               {t('set')}
            </Button>
         </Stack>
      </Modal>
   )

   const modalTo = (
      <Modal fullScreen={isMobile} size={'xl'} title={t('to')} opened={openModalTo} onClose={() => setOpenModalTo(false)}>
         <Stack>
            <TextInput
               label={t('recivername')}
               value={receiver}
               data-autofocus
               onChange={(e) => {
                  setReceiver(e.target.value)
               }}
            />

            <Map
               func={toto}
               centr={{
                  latitude: selectedCity ? selectedCity?.latLang[1] : 46.2044,
                  longitude: selectedCity ? selectedCity?.latLang[0] : 6.1432,
                  zoom: 12
               }}
            />
         </Stack>
         <Stack spacing={1} sx={{ paddingTop: '70px' }} direction={'row'}>
            <Button
               onClick={(e) => {
                  setOpenModalTo(false)
               }}>
               {t('set')}
            </Button>
         </Stack>
      </Modal>
   )

   const deleteTemplate = async (name) => {
      delete templates[name]

      var newtemp = { ...templates }

      setTemplates(newtemp)

      await localStorage.setItem('templates_' + user._id, JSON.stringify(templates))
   }

   const form = (
      <>
         <Divider variant='dotted' />
         {modalTo} {modalFrom}
         <Group>
            <AiOutlineCloudDownload style={{ fontSize: '15px' }} />
            <Text c='dimmed' size={'xs'}>
               {t('loadtemp')}
            </Text>
         </Group>
         <Group>
            {templates &&
               Object.entries(templates).map((template, key) => {
                  return (
                     <div key={key}>
                        <Chip
                           key={key}
                           style={{ backgroundColor: theme.colorScheme === 'dark' ? '#7f7f7' : '#f5f5f5', color: theme.colorScheme === 'dark' ? 'gray' : '#0c0c0c' }}
                           label={template[0]}
                           onClick={(e) => {
                              setTemplate(template[0])
                           }}
                           onDelete={(e) => {
                              deleteTemplate(template[0])
                           }}
                        />
                     </div>
                  )
               })}
         </Group>
         <Divider variant='dotted' />
         <Title order={6}>{t('scheaduletime')}</Title>
         <Group grow>
            <Group>
               <Switch
                  size='xl'
                  checked={immediate}
                  onChange={(event) => {
                     setImmediate(event.target.checked)
                  }}
               />
               <Text c='dimmed' size={'xs'}>
                  {t('immidiate')}
               </Text>
            </Group>
            <DateTimePicker
               dropdownType='modal'
               placeholder='Pick date and time'
               maw={400}
               label={t('scheaduletime')}
               disabled={immediate}
               value={time}
               onChange={setTime}
               defaultDate={new Date(2021, 7)}
               getDayProps={(date) => {
                  if (date.getDay() === 5 && date.getDate() === 13) {
                     return {
                        sx: (theme) => ({
                           backgroundColor: theme.colors.red[theme.fn.primaryShade()],
                           color: theme.white,
                           ...theme.fn.hover({ backgroundColor: theme.colors.red[7] })
                        })
                     }
                  }

                  return {}
               }}
               getYearControlProps={(date) => {
                  if (date.getFullYear() === new Date().getFullYear()) {
                     return {
                        sx: (theme) => ({
                           color: theme.fn.primaryColor(),
                           fontWeight: 700
                        })
                     }
                  }

                  if (date.getFullYear() === new Date().getFullYear() + 1) {
                     return { disabled: true }
                  }

                  return {}
               }}
               getMonthControlProps={(date) => {
                  if (date.getMonth() === 1) {
                     return {
                        sx: (theme) => ({
                           color: theme.fn.primaryColor(),
                           fontWeight: 700
                        })
                     }
                  }

                  if (date.getMonth() === 5) {
                     return { disabled: true }
                  }

                  return {}
               }}
            />
         </Group>
         <Group grow>
            <Group>
               <Switch
                  size='xl'
                  onChange={(event) => {
                     setHourly(event.target.checked)
                  }}
                  checked={hourly}
               />
               <Stack spacing={0}>
                  <Text size={'xs'} color='dimmed'>
                     {t('hourlyRate')}
                  </Text>
                  <Group>
                     <Stack spacing={0} style={{ width: '100px' }}>
                        <Text size={10}>{t('activateHourlyRate')}</Text>
                     </Stack>
                  </Group>
               </Stack>
            </Group>
            <Group>
               <TextInput
                  type='number'
                  disabled={!hourly}
                  value={hourlyRate}
                  min={1}
                  onChange={(e) => {
                     setHourlyRate(e.target.value)
                  }}
               />
               <Text c='dimmed' size={'xs'}>
                  Hours
               </Text>
            </Group>
         </Group>
         <Divider variant='dotted' />
         <Group grow position='apart'>
            {cities && (
               <>
                  <Stack>
                     <Title order={6}>{t('city')}</Title>
                     <Select
                        size='lg'
                        value={city}
                        data={cities}
                        placeholder={city}
                        onChange={(ev) => {
                           setCity(ev)
                           let result = cityList.filter((e) => e.name === ev)

                           setSelectedCity(result[0])
                        }}></Select>
                  </Stack>
               </>
            )}
         </Group>
         <Grid grow>
            <Grid.Col span={4}>
               <Card
                  createstyles={{ minHeight: '170px' }}
                  withBorder
                  onClick={(e) => {
                     setOpenModalFrom(true)
                  }}
                  className={classes.carda}>
                  <Group>
                     <BsPinMap size={20} />
                     <Title order={6}>{t('from')}</Title>
                     <Text size={'xs'} weight={700} c='dimmed'>
                        {from.place_name && sender}
                     </Text>

                     <Text size={'xs'}>{get(from, 'place_name', '...')}</Text>
                  </Group>
               </Card>
            </Grid.Col>
            <Grid.Col span={4}>
               <Card
                  hidden={hourly}
                  withBorder
                  onClick={(e) => {
                     setOpenModalTo(true)
                  }}
                  className={classes.carda}>
                  <Group>
                     <BsFillPinMapFill size={20} />
                     <Title order={6}>{t('to')}</Title>
                     <Text>{to.place_name && receiver}</Text>
                     <Text size={'xs'}>{get(to, 'place_name', '...')}</Text>

                     <br />
                  </Group>
               </Card>
            </Grid.Col>
         </Grid>
         <Stack spacing={10}>
            <Title order={6}>{t('requirements')}</Title>
            <Text size='sm'>
               {t('markuptext1')}
               {selectedCity?.zones && selectedCity.zones.heavyDetail && <Text c='dimmed'>if heavier than {selectedCity?.zones && selectedCity.zones.heavyDetail} KG</Text>}
            </Text>
            <Text size='sm' c='dimmed'>
               {t('markuptext2')}
            </Text>
            <Requirements requirements={requirements} setRequirements={setRequirements} />

            <Group>
               <Checkbox onChange={handleChangeRequirements} checked={requirements.HeavyBulky} id='HeavyBulky' label={t('heavy')} />
               <TextInput type='number' name='fname' onChange={(e) => setWeight(e.target.value)} value={weight} disabled={disabled} style={{ width: '50px', padding: '5px', borderRadius: '10px', borderColor: 'linen' }} />
               KG {selectedCity?.zones && selectedCity.zones.heavyDetail && <Text c='dimmed'>Check if heavier than {selectedCity?.zones && selectedCity.zones.heavyDetail} KG</Text>}
            </Group>
         </Stack>
         <Title order={6}>{t('b_vehicle')}</Title>
         <Select size='xl' value={vehicle} data={data} onChange={setVehicle} />
         <div className='invalid'>{error}</div>
         <Button size='xl' fullWidth className='mission-button' onClick={formSubmit}>
            {t('next')}
         </Button>
      </>
   )

   const setTemp = (e) => {
      console.log(tempName)
      missionSaveForLater(tempName)
   }

   const openModal = () =>
      modals.openConfirmModal({
         title: t('saveTemplate'),
         children: (
            <Stack>
               <TextInput ref={inputRef} />
            </Stack>
         ),

         onCancel: () => console.log('Cancel'),
         labels: { confirm: 'Save', cancel: 'Cancel' },
         onConfirm: () => {
            missionSaveForLater(inputRef.current.value)
            inputRef.current.value = ''
         }
      })

   const check = (
      <>
         <Card>
            <Stack spacing={30}>
               <h3>{t('sugestedprice')}</h3>
               <Text>
                  Driving distance <b>{Number(distance).toFixed(2)} km's</b>
               </Text>
               <h3 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '55px' }}>
                  {calcPrice && calcPrice.posterCharge.toFixed(2)} {calcPrice && calcPrice.currency}
               </h3>
               <TextInput id='outlined-textarea' size='md' description={t('blank')} label={t('suggestanother')} type={'number'} placeholder=' ' value={suggestedPrice} onChange={(e) => setSuggestedPrice(e.target.value)} />
               <Divider />
               <Stack gap='20px'>
                  <Textarea id='outlined-textarea' size='md' label={t('comment')} placeholder=' ' multiline rows={4} value={comment} onChange={(e) => setComment(e.target.value)} />

                  <TextInput id='outlined-textarea' size='md' label={t('internalref')} placeholder=' ' value={internalReference} onChange={(e) => setinternalRererence(e.target.value)} />
                  <div className='invalid' style={{ alignContent: 'center', justifyContent: 'center' }}>
                     {error}
                  </div>
               </Stack>
               <p>
                  <small style={{ color: 'GrayText' }}>{t('missiontext')}</small>
               </p>
               <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px' }}>
                  <Button onClick={() => setStep(1)} variant='outline' color='secondary'>
                     {'<'} {t('back')}
                  </Button>

                  <Button color='secondary' variant='light' className='mission-button' onClick={openModal}>
                     {t('save')}
                  </Button>
                  <Button loading={isLoading} onClick={missionCreate}>
                     {t('confirmPost')}
                  </Button>
               </div>
            </Stack>
         </Card>
      </>
   )

   const accept = (
      <Card>
         <div style={{ margin: '50px', textAlign: 'center' }}>
            <div ref={componentRef}>
               <h1>{t('thanks')}</h1>
               <p>{t('thanks1')}</p>
               <p>
                  with
                  <b> Reference # {shortReference} </b>
               </p>
               {t('mistext7')}
            </div>

            <Stack style={{ padding: '50px' }}>
               <Group position='apart'>
                  <Button loading={isLoading} onClick={missionClose} variant='light'>
                     {t('backHome')}
                  </Button>

                  <ReactToPrint
                     trigger={() => {
                        return (
                           <Button variant='outline' href='#'>
                              {t('print')}
                           </Button>
                        )
                     }}
                     content={() => componentRef.current}
                  />
               </Group>
            </Stack>
         </div>
      </Card>
   )

   const renderSwitch = () => {
      switch (step) {
         case 2:
            return check

         case 3:
            return accept

         default:
            return form
      }
   }

   return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
         <Head type='main' />

         <Card>
            <Stack>
               <Group position='apart'>
                  <Title order={6}>{t('newMission')}</Title>

                  <Anchor href='/logged/poster/billing' size='xs' color='dimmed' sx={{ lineHeight: 1 }}>
                     <Group position='right' spacing={5} align='center'>
                        <BsCreditCard2Front />
                        <Text tt='uppercase'>{t('h_personal')} </Text>
                     </Group>
                  </Anchor>
               </Group>

               {renderSwitch()}
            </Stack>
         </Card>
      </LocalizationProvider>
   )
}

export default Missions

// refactor this code to files
