import { useState, useEffect } from 'react'

const defaultSettings = {
   enableHighAccuracy: false,
   timeout: Infinity,
   maximumAge: 0
}

export const usePosition = (watch = true, userSettings = {}) => {
   const settings = {
      ...defaultSettings,
      ...userSettings
   }

   const [position, setPosition] = useState({})
   const [error, setError] = useState(null)

   const onChange = ({ coords, timestamp }) => {
      setPosition({
         latitude: coords.latitude,
         longitude: coords.longitude,
         accuracy: coords.accuracy,
         speed: coords.speed,
         heading: coords.heading,
         timestamp
      })
   }

   const onError = (error) => {
      setError(error.message)
   }

   useEffect(() => {
      if (!navigator || !navigator.geolocation) {
         setError('Geolocation is not supported')
         return
      }

      const watcher = navigator.geolocation.watchPosition(onChange, onError, settings)
      return () => navigator.geolocation.clearWatch(watcher)
   }, [settings.enableHighAccuracy, settings.timeout, settings.maximumAge])

   return { ...position, error }
}
