import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { Select, Title, Stack } from '@mantine/core'
import { useTranslation } from 'react-i18next'

const apiServer = process.env.REACT_APP_API_URL

function CitiSelect({ selectedCity, setSelectedCity, ...others }) {
	const { t } = useTranslation()

	const [cities, setCities] = useState([])

	const setcity = (city) => {
		sessionStorage.setItem('city', city)
		city && setSelectedCity(city)
	}

	const getCities = async () => {
		const ctis = sessionStorage.getItem(cities)
			? JSON.parse(sessionStorage.getItem(cities))
			: await axios.get(apiServer + '/cities').then((res) => {
					sessionStorage.setItem('cities', JSON.stringify(res.data))
					return res.data
			  })

		const citiList = ctis.map((city, key) => ({ value: city.name, label: city.name, key: key }))

		setCities(citiList)

		return
	}

	useEffect(() => {
		getCities()
		sessionStorage.getItem('city') || sessionStorage.setItem('city', selectedCity ? selectedCity : 'Geneva')
		sessionStorage.getItem('city') || setSelectedCity('Geneva')
	}, [])

	return (
		<Stack spacing={4}>
			<Title order={5}>{t('b_city')}</Title>
			<Select
				style={{ width: '100%' }}
				value={selectedCity}
				data={cities}
				onChange={(e) => {
					setcity(e)
				}}
				placeholder='Select City'
				{...others}
			/>
		</Stack>
	)
}

export default CitiSelect
