import React, { useContext } from 'react'
import moment from 'moment'
import PosterBox from './helpers/posterBox'

/// ICON IMPORTS

import { AiOutlineCar } from 'react-icons/ai'

import { GiFootprint } from 'react-icons/gi'
import { MdPedalBike, MdUpdate } from 'react-icons/md'

import { UserContext } from '../../_context'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '@mantine/hooks'

import Map from '../tools/mapc'

import Rating from '../rating'

/// MUI IMPORTS

import { FiGitBranch } from 'react-icons/fi'

import { Button, Stack, Card, Group, Text, SimpleGrid, Badge, Tooltip, Title, Select } from '@mantine/core'

import { useClipboard } from '@mantine/hooks'

import StepperMissions from '../tools/StepperMissions'

import AttachedRequirements from '../tools/AttachedRequirements'

import axios from 'axios'

// MAIN EXPORT FUNCTION
function Mission({ mission, closeFunc }) {
   const isMobile = useMediaQuery('(max-width: 50em)')

   const { t } = useTranslation()

   const clipboard = useClipboard()

   const steps = ['Available', 'Booked', 'InTransit', 'Delivered']

   const [missionStatus, setMissionStatus] = React.useState(mission.status)

   const apiServer = process.env.REACT_APP_API_URL

   const [user] = useContext(UserContext)

   const config = { headers: { Authorization: `Bearer ${user.token}` } }

   const setRating = (value) => {
      console.log(value)

      // mission: req.body.mission,
      // 		poster:  req.body.poster,
      // 		booker: req.authUser._id,
      // 		posterRating: req.body.posterRating,
      // 		bookerRating: req.body.bookerRating,

      axios
         .post(apiServer + `/poster/rate`, { rating: value, mission: mission._id, poster: mission.poster }, config)
         .then((res) => {
            console.log(res)
         })
         .catch((err) => {
            console.log(err)
         })
   }

   const handleChange = async (event) => {
      await axios
         .put(
            apiServer + `/booker/mission/${mission._id}`,
            {
               status: event
            },
            config
         )
         .then((res) => {
            setMissionStatus(event)
            closeFunc()
         })
         .catch((err) => {
            console.log(err)
         })

      if (event === 'delivered') {
         //axios.post('/api/mission/complete', { missionId: mission.id })
         setActiveStep(1)
      }
   }

   const All = () => {
      return (
         <div>
            <AiOutlineCar />
            <MdPedalBike />
            <GiFootprint />
         </div>
      )
   }

   const [activeStep, setActiveStep] = React.useState(0)

   if (activeStep === 0)
      return (
         // START OF div
         <Stack>
            <StepperMissions mission={mission} steps={steps} hidden={isMobile} />
            <Card withBorder>
               <Group position='apart' align='top'>
                  <Stack spacing={0}>
                     <Text weight={700} size='sm'>
                        {t('pickup_time')} {moment(mission.time).calendar()}
                     </Text>
                     <Group>
                        {mission.immediate && <div className={'immediate'}> {mission.immediate ? 'immediate' : ''}</div>}
                        {mission.hourly && (
                           <Badge size='xs' color='yellow'>
                              {mission.hourly ? t('hourly') + ' +' + mission.hourlyRate + ' hrs.' : ''}
                           </Badge>
                        )}
                     </Group>
                     <Group position='apart' grow>
                        <AttachedRequirements mission={mission} />
                     </Group>
                  </Stack>
                  <Stack align='flex-end'>
                     <Text size='xl' weight={900}>
                        Net
                     </Text>
                     <Text size='xl' weight={900}>
                        {mission.pricing.price.bookerNet} {mission.pricing.currency.name}
                     </Text>
                  </Stack>
                  <Stack>
                     <Group>
                        <Group spacing='lg'>
                           <Stack>
                              <Tooltip width={80} multiline label={'Copied to clipboard!'} transitionProps={{ duration: 300, transition: 'slide-down' }} opened={clipboard.copied}>
                                 <Button radius={5} size='xs' variant='default' uppercase onClick={() => clipboard.copy(mission.shortReference)}>
                                    {mission.shortReference}
                                 </Button>
                              </Tooltip>
                              <Text size={'xs'} weight={700} c='dimmed'>
                                 {t('b_mode')}
                              </Text>

                              <Group position='apart' align='center' grow spacing={'lg'}>
                                 {mission.deliveryMode === 'all' ? (
                                    <Button variant='outline'>
                                       <All />
                                    </Button>
                                 ) : (
                                    ''
                                 )}
                                 {mission.deliveryMode === 'bicycle' ? (
                                    <Button variant='outline'>
                                       <MdPedalBike />
                                    </Button>
                                 ) : (
                                    ''
                                 )}
                                 {mission.deliveryMode === 'foot' ? (
                                    <Button variant='outline'>
                                       {' '}
                                       <MdPedalBike />
                                    </Button>
                                 ) : (
                                    ''
                                 )}
                                 {mission.deliveryMode === 'car' ? (
                                    <Button variant='outline'>
                                       {' '}
                                       <AiOutlineCar />
                                    </Button>
                                 ) : (
                                    ''
                                 )}
                              </Group>
                           </Stack>
                        </Group>
                     </Group>
                  </Stack>
               </Group>
            </Card>

            <SimpleGrid cols={2} breakpoints={[{ maxWidth: 'md', cols: 1, spacing: 'md' }]}>
               <Stack>
                  <Group grow>
                     {mission.status !== 'delivered' && (
                        <Stack>
                           <Select
                              size='lg'
                              icon={<MdUpdate />}
                              value={missionStatus}
                              onChange={handleChange}
                              data={[
                                 { label: t('booked'), value: 'booked' },
                                 { label: t('picked'), value: 'inTransit' },
                                 { label: t('completed'), value: 'delivered' }
                              ]}
                           />
                        </Stack>
                     )}
                     {console.log(user)}
                     {process.env.NODE_ENV !== 'production' && (
                        <Select
                           size='lg'
                           icon={<FiGitBranch />}
                           value={missionStatus}
                           onChange={handleChange}
                           data={[
                              { label: t('Available'), value: 'available', icon: <AiOutlineCar /> },
                              { label: t('booked'), value: 'booked', icon: <AiOutlineCar /> },
                              { label: t('picked'), value: 'inTransit', icon: <AiOutlineCar /> },
                              { label: t('completed'), value: 'delivered', icon: <AiOutlineCar /> }
                           ]}
                        />
                     )}
                  </Group>
                  <Map mission={mission} width='100' height='100' />
               </Stack>

               <Stack>
                  <Card withBorder>
                     <Group>
                        <Text weight={700}>{t('from')}.</Text>
                        <Text size='xs' color='dimmed'>
                           {mission.sender}
                        </Text>
                        <Text size='xs' c='dimmed'>
                           {mission.from.place_name}
                        </Text>
                     </Group>
                  </Card>
                  <Card withBorder>
                     <Group>
                        <Text weight={700}>{t('to')}.</Text>
                        <Text size='xs' color='dimmed'>
                           {mission.receiver}
                           {mission.hourly && (
                              <Badge size='xs' color='yellow'>
                                 {mission.hourly ? t('hourly') + ' +' + mission.hourlyRate + ' hrs.' : ''}
                              </Badge>
                           )}
                        </Text>
                        <Text size='xs' c='dimmed'>
                           {mission.createType === 'qr' ? t('destinationqr') : mission.to.place_name}
                        </Text>
                     </Group>
                  </Card>
                  {mission.status !== 'delivered' ? <PosterBox mission={mission} /> : <></>}
                  <Stack>
                     <Card withBorder>
                        <Text size={'xs'} weight={700} c='dimmed'>
                           <b>{t('comment')}.</b>
                           {mission.comment}
                        </Text>
                     </Card>
                  </Stack>

                  {mission.status === 'delivered' && (
                     <Card withBorder>
                        <Stack>
                           <Text size={'xs'} weight={700} c='dimmed'>
                              {t('Rate Poster')}
                           </Text>
                           <Rating onChange={setRating} />
                        </Stack>
                     </Card>
                  )}
               </Stack>
            </SimpleGrid>
         </Stack>
      )

   if (activeStep === 1) {
      return (
         <Stack>
            <Title>Congratulations!</Title>
            <Text>You have successfully Completed the Mission #</Text>
            <Text color='light'>{mission.shortReference}</Text>
            <Stack spacing={100}>
               {/* <Text>Please Upload A photo of the location you left the package to avoid any misplacement</Text> */}
               <Card withBorder>
                  <Text size={'xs'} weight={700} c='dimmed'></Text>
                  {t('Rate Booker')}.
                  <Rating value={mission.rating} readOnly />
               </Card>
            </Stack>
            <Button onClick={closeFunc}>Close</Button>
         </Stack>
      )
   }
} // end component

export default Mission
