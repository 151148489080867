import React from 'react'
import { Ri24HoursFill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next'
import { Card, Stack, Badge, Text, Anchor, Group } from '@mantine/core'

const NoMissions = ({ book }) => {
   return (
      <>
         <Card key='0' shadow='lg' padding={100} align='center' justify='center' withBorder>
            <Stack direction='column' spacing='lg' align='center' justify='center'>
               <Ri24HoursFill size={100} color='gray' />
               <Text c='dimmed' weight={700} align='center'>
                  No Waiting Missions
               </Text>
               <Group position='center' align='center' justify='center'>
                  <Badge align='center'>{sessionStorage.getItem('city') ? sessionStorage.getItem('city') : 'Geneva'} </Badge>
               </Group>
               <Text c='dimmed' align='center'>
                  Please <Anchor onClick={() => book('third')}>Book A Mission</Anchor> to Start.
               </Text>
            </Stack>
         </Card>
      </>
   )
}

export default NoMissions
