import React from 'react'
import moment from 'moment'
import 'moment/locale/fr-ch'

import get from 'lodash/get'
import { CgArrowLongRightC } from 'react-icons/cg'

import { MdOutlineFastfood } from 'react-icons/md'

import { RiTempHotFill } from 'react-icons/ri'

import { IoDocumentsOutline } from 'react-icons/io5'
import { GiShatteredGlass, GiThermometerCold, GiWeight } from 'react-icons/gi'

import { FaShippingFast } from 'react-icons/fa'

import { GoLaw } from 'react-icons/go'

import { useTranslation } from 'react-i18next'
import { Badge, Group, Stack, Button, Card, createStyles } from '@mantine/core'

function Mission({ mission, setSelectedMission }) {
   const color = (status) => {
      switch (status) {
         case 'available':
            return 'green'
         case 'booked':
            return 'blue'
         case 'inTransit':
            return 'orange'
         case 'delivered':
            return 'green'
         case 'underReview':
            return 'yellow'
         default:
            return 'gray'
      }
   }

   const useStyles = createStyles((theme) => ({
      card: {
         backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0]
      },

      title: {
         fontFamily: `Greycliff CF, ${theme.fontFamily}`,
         fontWeight: 700
      },

      item: {
         border: '1px solid dark',
         backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : '#fff',

         '&:hover': {
            border: '1px solid  darkgray',

            boxShadow: theme.shadows.md
         }
      }
   }))

   const { t, i18n } = useTranslation()
   /// Icon Size
   const iconSize = 22
   const { classes } = useStyles()

   moment.locale(i18n.language === 'fr' ? 'fr-ch' : 'en')

   return (
      <Card shadow='sm' className={classes.item} style={{ cursor: 'pointer' }} withBorder onClick={(e) => setSelectedMission(mission)}>
         <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '15px', cursor: 'pointer' }}>
            <div className='prevent-select'>
               <div style={{ fontWeight: 'bold', textAlign: 'left' }}>
                  {t('pickup_time')} {moment(get(mission, 'time', false)).format('lll')}
                  {mission.hourly ? ' (Hourly)' : ''}
               </div>

               <div className='post-list-from-to'>
                  {get(mission.from, 'text', '')}
                  <div>
                     <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        <CgArrowLongRightC size={24} />
                     </div>
                  </div>

                  {get(mission.to, 'text', '')}
               </div>
            </div>

            <Stack spacing='xs'>
               <Stack spacing='5px' className='noselect'>
                  <Button radius={5} size='xs' variant='default' uppercase>
                     {mission.shortReference}
                  </Button>

                  <Badge size='xs' color={color(mission.status)}>
                     {t(mission.status)}
                  </Badge>

                  {mission.immediate && (
                     <Badge size='xs' color='red'>
                        {mission.immediate ? t('immidiate') : ''}
                     </Badge>
                  )}
                  {mission.hourly && (
                     <Badge size='xs' color='yellow'>
                        {mission.hourly ? t('hourly') + ' +' + mission.hourlyRate + ' hrs.' : ''}
                     </Badge>
                  )}
               </Stack>
            </Stack>
         </div>

         <div style={{ display: 'flex', padding: '8px', justifyContent: 'space-between' }}>
            <Group spacing={5}>
               {mission.requirements?.Food && <MdOutlineFastfood size={iconSize} />}
               {mission.requirements?.KeepCold && <GiThermometerCold size={iconSize} />}
               {mission.requirements?.Warm && <RiTempHotFill size={iconSize} />}
               {mission.requirements?.Documents && <IoDocumentsOutline size={iconSize} />}
               {mission.requirements?.Fragile && <GiShatteredGlass size={iconSize} />}
               {mission.requirements?.Urgent && <FaShippingFast size={iconSize} />}
               {mission.requirements?.Critical && <GoLaw size={iconSize} />}

               {mission.requirements?.HeavyBulky && <GiWeight size={iconSize} />}

               {mission.weight > 0 && <span className='weight'>{mission.weight} Kg</span>}
            </Group>
            <div className='price'>
               {mission.pricing.price.posterCharge} {mission.pricing.currency.name}
            </div>
         </div>
      </Card>
   )
} // end component

export default Mission
