import { useMantineColorScheme, ActionIcon, Group } from '@mantine/core'
import { IconSun, IconMoonStars } from '@tabler/icons-react'

export function ActionToggle() {
	const { colorScheme, toggleColorScheme } = useMantineColorScheme()

	// Setup Color for main menu controls

	return (
		<Group position='center' my='lg'>
			<ActionIcon onClick={() => toggleColorScheme()}>{colorScheme === 'dark' ? <IconSun /> : <IconMoonStars />}</ActionIcon>
		</Group>
	)
}
