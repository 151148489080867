import React, { useState, useEffect, useRef } from 'react'
import axios from 'axios'

import { useForm, isEmail, isNotEmpty, hasLength } from '@mantine/form'

import { TextInput, Text, Stack, Button, Group, PasswordInput, Anchor, useMantineTheme, Title, Checkbox, Card } from '@mantine/core'
 
import PhoneInput from 'react-phone-input-2'

import { useTranslation } from 'react-i18next'

import CitiSelect from '../tools/citiSelect'

 
const apiServer = process.env.REACT_APP_API_URL

const RegisterForm = ({ userType }) => {
	const { colorScheme } = useMantineTheme()
	const { t } = useTranslation()

	const [waiting, setWaiting] = useState(false)
	const [invalid, setInvalid] = useState('.')
	const [success, setSuccess] = useState(false)
	const [cities, setCities] = useState([])
	const [city, setCity] = useState('')
	const [password, setPassword] = useState('')
	const [agree, setAgree] = useState(false)
	
 
	const [phone, setPhone] = useState('')

	const passRef = useRef()

	const checkRef = useRef()

	const form = useForm({
		initialValues: {
			name: '',
			email: '',
			phone: '',
			password: '',
			city: '',
			type: userType,
			terms: false,
		},

		// functions will be used to validate values at corresponding key
		validate: {
			name: hasLength({ min: 2, max: 20}),
			email: isEmail(),
			phone: isNotEmpty(), 
			password: hasLength({ min: 8, max: 20 }),
			city: isNotEmpty(),
			terms: isNotEmpty(),
		},
	})

	const log = (obj) => {
		setWaiting(true)

		axios
			.post(apiServer + '/register', obj)
			.then((response) => {
				setSuccess(true)
			})
			.catch((error) => {
				setInvalid('Email or phone is already registered with another user')
				setTimeout(() => setInvalid('.'), 3 * 1000)
			})
			.finally(() => setWaiting(false))
	}

	 
	 

	const passSettera = (e) => {

		const obj = { ...form.values,e }
		form.isValid('terms') || 	setInvalid('You must agree to the terms and conditions')

			form.isValid('city') || 	setInvalid('City is not valid')
		
		
			form.isValid('password') || 	setInvalid('Password must be between 8 and 20 characters')
	
		form.isValid('phone') || 	setInvalid('Phone is not valid')
		form.isValid('email') || 	setInvalid('Email is not valid')
		form.isValid('name') ||  	setInvalid('Name must be between 2 and 20 characters')

		setTimeout(() => setInvalid('.'), 3 * 1000)



	

		form.isValid() && log(obj)

		 
 
		
		
	}

	if (success) {
		return (
			<div style={{ textAlign: 'center', padding: '30px' }}>
				<p style={{ textAlign: 'center', padding: '50px' }}>
					{t('regSuccess')} {t(form.values.type)}.<br />
					{t('regSend')} <b>{form.values.email}</b>.{' '}
				</p>
				<Button color='primary' size='large' variant='contained' onClick={() => window.location.reload()}>
					{t('goLogin')}
				</Button>
			</div>
		)
	}



	return (
		<form style={{ padding: '30px', textAlign: 'left' }}>
			<Stack spacing={20}>
				<Group grow align='top'>
					<Stack spacing={4}>
						<Title order={5}>{t('name')}</Title>
						<TextInput size='lg' id='name' autoComplete='name' {...form.getInputProps('name')} />
					</Stack>
					<Stack spacing={4}>
						<Title order={5}>{t('email')}</Title>
						<TextInput id='email' size='lg' autoComplete='email' {...form.getInputProps('email')} />
					</Stack>
				</Group>

				<Stack>
					<Title order={5}>{t('YourPhone')}</Title>
					<PhoneInput
						id='phone'
						size='xl'
						specialLabel=''
						inputStyle={{
							border: '1px solid red' ,
							borderRadius: '10px',
							width: '100%',
							fontSize: '22px',
							color: colorScheme === 'dark' ? '#ccc' : '#333',
							borderColor: colorScheme === 'dark' ? '#333' : '#cacaca',
							backgroundColor: 'none',
						}}
						buttonStyle={{
							color: colorScheme === 'dark' ? '#ccc' : '#333',

							borderColor: colorScheme === 'dark' ? '#333' : '#cacaca',
							backgroundColor: 'none',
						}}
						country={'ch'}
						autoComplete='phone'
						value={phone}
						 
						onChange={(phone) => {
							setPhone(phone)
							form.values.phone = phone
						}}

					 

						defaultErrorMessage="It doesn't works, why?"
						 
					/>
				</Stack>
				<Title order={5}>{t('password')}</Title>

				<PasswordInput size="xl" id='password' value={password}  {...form.getInputProps('password')} />
				 
				<CitiSelect id="" size='xl' selectedCity={city} setSelectedCity={setCity}  {...form.getInputProps('city')} />

				<Card withBorder shadow='xs' p={30}>
					<Stack spacing={"xl"}>
						<Text size={12} c='dimmed'>
							{t('terms')}
						</Text>
						<Group>
							<Checkbox
							    id = "terms"
								label={
									 
									<Group spacing={10} >
										<Text >{t('agree')} </Text>
										<Anchor   href='https://pedallo.com/gtc/' target='_blank' rel='noreferrer'>
											<Text weight={700}>{t('termsConditions')}</Text>
										</Anchor>
										</Group>
									 
								}
								value={agree}
							 
								{...form.getInputProps('terms')}
							/>
						</Group>
					</Stack>
				</Card>

				<div className='invalid'>{invalid}</div>

				<Button loading={waiting} size='lg' mt='sm' onClick={(e)=>passSettera()}>
					{t('submit')}
				</Button>
			</Stack>
		</form>
	)
}

export default RegisterForm
