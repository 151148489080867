import React, { useState, useRef, useCallback, useEffect } from 'react'

import { FaMapMarker } from 'react-icons/fa'

import MapGL, { Marker } from 'react-map-gl'

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_KEY

const Map = ({ mission }) => {
   const centr = {
      latitude: mission.from.geometry.coordinates[1] ? Number(mission.from.geometry.coordinates[1]) : 1,
      longitude: mission.from.geometry.coordinates[0] ? Number(mission.from.geometry.coordinates[0]) : 1,
      zoom: 16
   }
   const pickup = {
      latitude: mission.from.geometry.coordinates[1] ? Number(mission.from.geometry.coordinates[1]) : 1,
      longitude: mission.from.geometry.coordinates[0] ? Number(mission.from.geometry.coordinates[0]) : 1,
      zoom: 12
   }

   const [viewport, setViewport] = useState(pickup)

   const mapRef = useRef()

   useEffect(() => {
      if (!mission.to) return
   }, [mission])

   const handleViewportChange = useCallback((newViewport) => setViewport(newViewport), [])

   // if you are happy with Geocoder default settings, you can just use handleViewportChange directly

   const [settings] = useState({
      dragRotate: false,
      dragPan: false,

      touchZoom: false,
      scrollZoom: false,
      touchRotate: false,
      touchmove: false,
      keyboard: false,
      doubleClickZoom: false,
      width: '100%',
      height: '100%'
   })

   return (
      <div style={{ height: '400px', width: '100%', pointerEvents: 'none' }}>
         <MapGL disabledref={mapRef} {...viewport} {...settings} onViewportChange={handleViewportChange} mapboxApiAccessToken={MAPBOX_TOKEN}>
            <Marker longitude={centr.longitude} latitude={centr.latitude} anchor='center-center'>
               <FaMapMarker size='30' color='green' />
            </Marker>

            {mission.to && (
               <Marker longitude={mission?.to?.geometry?.coordinates[0]} latitude={mission?.to?.geometry?.coordinates[1]} anchor='center-center'>
                  <FaMapMarker size='30' color='red' />
               </Marker>
            )}
         </MapGL>
      </div>
   )
}

export default Map
