import React from 'react'
import { MdOutlineFastfood } from 'react-icons/md'
import { RiTempHotFill } from 'react-icons/ri'
import { IoDocumentsOutline } from 'react-icons/io5'
import { GiShatteredGlass, GiThermometerCold, GiWeight } from 'react-icons/gi'
import { FaShippingFast } from 'react-icons/fa'
import { GoLaw } from 'react-icons/go'
import { Box, Group } from '@mantine/core'

const iconSize = 20

function AttachedRequirements({ mission }) {
   return (
      <Box style={{ paddingTop: '20px' }}>
         <Group spacing={'xs'}>
            {mission.requirements?.Food && <MdOutlineFastfood size={iconSize} />}
            {mission.requirements?.KeepCold && <GiThermometerCold size={iconSize} />}
            {mission.requirements?.Warm && <RiTempHotFill size={iconSize} />}
            {mission.requirements?.Documents && <IoDocumentsOutline size={iconSize} />}
            {mission.requirements?.Fragile && <GiShatteredGlass size={iconSize} />}
            {mission.requirements?.Urgent && <FaShippingFast size={iconSize} />}
            {mission.requirements?.Critical && <GoLaw size={iconSize} />}

            {mission.requirements?.HeavyBulky && <GiWeight size={iconSize} />}

            {mission.weight > 0 && <span className='weight'>{mission.weight} Kg</span>}
         </Group>
      </Box>
   )
}

export default AttachedRequirements
