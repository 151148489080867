// store users credit card information via stripe api

import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../_context'

import { Card, Button, Stack } from '@mantine/core'
import axios from 'axios'

import { FaCcVisa, FaCcMastercard } from 'react-icons/fa'

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'

import { useTranslation } from 'react-i18next'

const CreditCard = ({ setMessage }) => {
   const { t } = useTranslation()

   const [user] = useContext(UserContext)

   const [intentSecret, setIntentSecret] = useState(false)

   const [card, setCard] = useState(false)

   const [cardAdded, setCardAdded] = useState(false)

   const [waiting, setWaiting] = useState(false)

   const [err, setErr] = useState('.')

   const apiServer = process.env.REACT_APP_API_URL

   const config = {
      headers: { Authorization: `Bearer ${user.token}` }
   }

   useEffect(() => {
      setWaiting(true)
      async function fetchData() {
         await axios
            .get(apiServer + '/stripe/customer', { headers: { Authorization: `Bearer ${user.token}` } })
            .then((response) => {
               if (response.data.card) {
                  setCard(response.data.card)
               }

               if (response.data.setupIntent) {
                  setIntentSecret(response.data.setupIntent)
               }
            })
            .catch((error) => {
               setWaiting(false)
               setErr(error.response.data)
            })

            .finally(() => {
               setWaiting(false)
            })
      }
      fetchData() // eslint-disable-next-line
   }, [cardAdded, apiServer, user.token])

   const stripe = useStripe()

   const elements = useElements()

   const submit = (event) => {
      setWaiting(true)
      setMessage('.')

      // ...send paymentMethod.id to your server
      if (!card) {
         if (!intentSecret) {
            setWaiting(false)

            setMessage('Please refresh page')
            return
         }

         stripe
            .confirmCardSetup(intentSecret, {
               payment_method: {
                  card: elements.getElement(CardElement)
               }
            })

            .then((result) => {
               if (result.error) {
                  // Inform the customer that there was an error.

                  setMessage(result.error.message)
                  setWaiting(false)
                  return
               }

               setCardAdded(true)
               setWaiting(false)
            })
      } else {
         axios
            .get(apiServer + '/stripe/deleteCard', config)

            .then((response) => {
               setCard(false)
               setCardAdded(false)
               setIntentSecret(response.data.setupIntent)
               setWaiting(false)
            })
            .catch((error) => {
               setWaiting(false)
               console.log(error.response)
               setErr(error.response.data)
               setMessage(err)
            })
      }
   }

   return (
      <Stack>
         <Card withBorder>
            {card ? (
               <div className='creditCard'>
                  <div>{card.brand === 'visa' ? <FaCcVisa /> : <FaCcMastercard />}</div>
                  <div>xxxx-xxxx-xxxx-{card.last4}</div>

                  <div>
                     {card.exp_month}/{card.exp_year}
                  </div>
               </div>
            ) : (
               <CardElement options={{ hidePostalCode: true }} />
            )}
         </Card>

         <Button leftIcon={<FaCcMastercard size='1rem' />} loading={waiting} type='submit' onClick={submit}>
            {card ? t('p_remcard') : t('p_addcard')}
         </Button>
      </Stack>
   )
}

export default CreditCard
