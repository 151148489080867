import React, { useContext, useState } from 'react'

import Head from './tools/head'
import Upload from './tools/upload'
import get from 'lodash/get'

// import stripe svg
import stripe from '../assets/stripe.svg'

import { UserContext } from '../_context'
import axios from 'axios'

import { ActionIcon, Card, Tooltip, Group, Stack, createStyles } from '@mantine/core'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import CreditCard from './tools/creditcard'

import { useTranslation, Trans } from 'react-i18next'
import { Title, Text, Button } from '@mantine/core'

import { BsQuestionSquare } from 'react-icons/bs'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const useStyles = createStyles((theme) => ({
	item: {
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		borderRadius: theme.radius.md,
		padding: 20,
		userSelect: 'none',
		cursor: 'pointer',
		color: theme.colorScheme === 'dark' ? '#fff' : '#fff',
		background: 'red',
		transition: 'box-shadow 150ms ease, transform 100ms ease',

		'&:hover': {
			background: '#000',
		},
	},

	title: {
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		fontWeight: 700,
	},
}))

const Profile = ({ userType }) => {
	const { t } = useTranslation()
	const apiServer = process.env.REACT_APP_API_URL

	const [user] = useContext(UserContext)
 
	const [ccMessage, setCCMessage] = useState()
	const { classes } = useStyles()

	const config = {
		headers: { Authorization: `Bearer ${user.token}` },
	}

	const getLink = (e) => {
		axios.get(apiServer + '/stripe/connect', config).then((response) => {
			console.log(response.data)

			window.open(response.data.stripeLink, '_blank')
		})
	}

	return (
		<>
			{userType === 'booker' ? <Head type='mainbooker' /> : <Head type='main' />}

			<Stack>
				<Card shadow='md' p='md' withBorder className={classes.card}>
					<Group position='apart'>
						<Title order={6}>{t('p_tui')} </Title>

						<Tooltip label='Upload only JPG/PDF Max 1MB !' multiline color='dark' position='bottom-end' withArrow>
							<ActionIcon size='lg'>
								<BsQuestionSquare />
							</ActionIcon>
						</Tooltip>
					</Group>

					<Stack>
						<Group>
							<Upload src={get(user, 'idPhoto.front', '/uploads/front.png')} loc='front' />

							<Upload src={get(user, 'idPhoto.back', '/uploads/back.png')} loc='back' />
							<Text size='xs' color='dimmed'>
								{t('p_inf1')}
							</Text>
						</Group>
					</Stack>
				</Card>

				<Card shadow='md' p='md' withBorder className={classes.card}>
					<Stack>
						<Group position='apart'>
							<Title order={6}>{t('p_tuc')} </Title>
							<Text fz='xs' c='red'>
								{ccMessage}
							</Text>
						</Group>
						<Elements stripe={stripePromise}>
							<CreditCard setMessage={setCCMessage} />
						</Elements>
					</Stack>
				</Card>

				<Card shadow='md' p='md' withBorder className={classes.card}>
					<Stack>
						<Title order={6}>{t('billing_connect')} </Title>
						<Text c='dimmed' size={'sm'}>
							<Trans i18nKey='onboard_popup' />
						</Text>

						<Group position='apart'>
							<Card withBorder shadow='sm' padding={10}>
								<Stack spacing={0}>
									<Text size={'xs'} c='dimmed'>
										Partnered With
									</Text>
									<img src={stripe} className='App-logo' alt='logo' width='100px' />
								</Stack>
							</Card>
							<div></div>
						</Group>

						<Button onClick={getLink}>{t('click_to_register_company')}</Button>
					</Stack>
				</Card>
			</Stack>
		</>
	)
}

export default Profile
