import React from 'react'
import { CgSmileNone } from 'react-icons/cg'
import { useTranslation } from 'react-i18next'
import { Card, Stack, Badge, Text, Group } from '@mantine/core'

const NoMissions = () => {
	const { t } = useTranslation()

	return (
		<>
			<Card key='0' shadow='lg' padding={100} align='center' justify='center' withBorder>
				<Stack direction='column' spacing='lg' align='center' justify='center'>
					<CgSmileNone size={100} color='gray' />
					<Text c='dimmed' weight={700} align='center'>
						{t('sorry1')}
					</Text>
					<Group>
						<Badge align='center'>{sessionStorage.getItem('city') ? sessionStorage.getItem('city') : 'Geneva'} </Badge>
						<Badge variant='outline' align='center'>
							{localStorage.getItem('vehicle') ? localStorage.getItem('vehicle') : 'All'}
						</Badge>
					</Group>
					<Text c='dimmed' align='center'>
						{t('sorry2')}
						<br />
						{t('sorry3')}
					</Text>
				</Stack>
			</Card>
		</>
	)
}

export default NoMissions
