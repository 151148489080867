import React, { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './components/tools/i18next'
import { CookiesProvider } from 'react-cookie'
import Loading from './components/tools/loading'

// Call it on top of project.

//import reportWebVitals from './reportWebVitals'

ReactDOM.render(
	<Suspense fallback={<Loading />}>
		<StrictMode>
			<CookiesProvider>
				<App />
			</CookiesProvider>
		</StrictMode>
	</Suspense>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log)
