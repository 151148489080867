import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import get from 'lodash/get'
import { UserContext } from '../../_context'

import { Card, Button, Stack, Image, Title, Select, Text } from '@mantine/core'

import { useTranslation } from 'react-i18next'

import CitiSelect from '../tools/citiSelect'

import { AiFillCar } from 'react-icons/ai'

import { BsBicycle } from 'react-icons/bs'

import { BiMapPin } from 'react-icons/bi'

import { FaWalking } from 'react-icons/fa'

import { MdOutlineElectricScooter, MdEmojiTransportation } from 'react-icons/md'

const Welcome = () => {
   const { t } = useTranslation()
   const [user] = useContext(UserContext)
   const navigate = useNavigate()
   const [city, setCity] = useState(sessionStorage.getItem('city') ? sessionStorage.getItem('city') : 'Geneva')

   user.vehicle = localStorage.getItem('vehicle') ? localStorage.getItem('vehicle') : 'car'

   const vehicleOptions = [
      { value: 'car', label: t('b_veh_opt_1'), icon: <AiFillCar /> },
      { value: 'motorcycle', label: t('b_veh_opt_3'), icon: <MdOutlineElectricScooter /> },
      { value: 'bicycle', label: t('b_veh_opt_2'), icon: <BsBicycle /> },
      { value: 'foot', label: t('b_veh_opt_4'), icon: <BsBicycle /> }
   ]
   const [vehicle, setVehicle] = useState(get(user, 'vehicle', vehicleOptions[0].value))

   const [icon, setIcon] = useState(<MdEmojiTransportation />)

   // Make this function in context provider

   const handleChangeVehicle = (event) => {
      let vType = ''

      switch (event) {
         case 'car':
            setIcon(<AiFillCar />)
            vType = 'car'
            break
         case 'bicycle':
            setIcon(<BsBicycle />)
            vType = 'bicycle'
            break
         case 'motorcycle':
            setIcon(<MdOutlineElectricScooter />)
            vType = 'motorcycle'
            break
         case 'foot':
            setIcon(<FaWalking />)
            vType = 'foot'
            break
         default:
            setIcon(<AiFillCar />)
            vType = 'car'
            break
      }

      localStorage.setItem('vehicle', event)
      setVehicle(event)
   }

   const next = (e) => {
      navigate('/logged/booker/dashboard')
   }

   return (
      <Card withBorder>
         <Stack direction='row' spacing={70}>
            <Image radius={'xl'} className='card-image' src='/booker.gif' alt='pedallo' />

            <Stack position='center' spacing='lg' style={{ padding: '40px' }}>
               <Stack spacing={2}>
                  <Title order={1}>{t('h_welecome')}</Title>
                  <Text tt='uppercase' weight={700}>
                     {user.name}
                  </Text>
                  <Text tt='uppercase'>{t('b_define')}</Text>
               </Stack>
               <Stack justify='apart' spacing={12}>
                  <CitiSelect size={'lg'} selectedCity={city} setSelectedCity={setCity} icon={<BiMapPin />} />
                  <Title order={5}>{t('b_vehicle')}</Title>
                  <Select size='lg' icon={icon} ize='xl' data={vehicleOptions} value={vehicle} onChange={handleChangeVehicle} />
               </Stack>
               <Button onClick={next}>{t('b_activate')}</Button>
            </Stack>
         </Stack>
      </Card>
   )
}

export default Welcome
